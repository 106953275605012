.appointment-parent {
  position: relative;
  height: 100%;
}
.appointment-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.appointment-main {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.appointment-left {
  width: 50%;
  margin-right: 1%;
  margin-bottom: 40px;
  margin-left: 4%;
}
.appointment-right {
  width: 44%;
  margin-left: 1%;
}
.css-k7hypl .rs__cell > button,
.css-xoukpm .rs__cell > button {
  pointer-events: none;
}
.appointment-main button {
  font-family: var(--montserrat);
  font-weight: bold;
}
.appointment-schedule-container span.rs__cell.rs__header span,
.appointment-schedule-container span.rs__cell.rs__header p {
  font-family: var(--montserrat);
}
.no-appointment {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
  font-family: var(--montserrat);
}
.no-appointment img {
  width: 50px;
  margin-right: 5px;
}

.appointment-schedule-upper,
.create-appointment-upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px var(--border-color);
  padding: 20px 30px;
}

.appointment-schedule-head,
.create-appointment-head {
  font-family: var(--montserrat);
  font-weight: bold;
  color: #51595e;
  font-size: 18px;
  width: 100%;
}
.appointment-schedule-head-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.appointment-schedule-head-top svg {
  color: #446fa2;
}
.appointment-schedule-head-lover-button {
  display: flex;
  justify-self: flex-end;
  gap: 2px;
  margin-top: 15px;
}
.appointment-schedule-head-lover-button button {
  color: #51595e;
  border: unset;
  cursor: pointer;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  background-color: #eeeeee;
}
.appointment-schedule-head-lover-button button:first-child {
  padding: 5px 25px;
}
.appointment-schedule-head-lover-button .activeTab {
  border: 1px solid #ececec;
  background-color: white;
  border-bottom-width: 0px;
}
.create-appointment-head-button {
  display: flex;
  align-items: center;
  gap: 20px;
}
.create-appointment-head-button button {
  font-size: 12px;
  white-space: nowrap;
  width: 167px;
}
.css-z3jy29 .rs__cell > button {
  pointer-events: none;
}
.create-appointment-lower {
  padding: 25px 15px;
}
.appointment-form-details {
  display: flex;
}
.appointment-form-details .data {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}
.appointment-form-details .icon {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  padding: 15px;
  border-radius: 50%;
  margin-right: 30px;
  width: 50px;
  height: 50px;
}
.appointment-form-details .data > * {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}
.appointment-form-details .data input {
  padding: 10px;
  color: #51595e;
  font-family: var(--lato);
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 12px;
}
.appointment-form-details .data textarea {
  padding: 10px;
  color: #51595e;
  font-family: var(--lato);
  font-size: 13px;
  resize: none;
}
.appointment-form-date .icon {
  margin-right: 30px;
  padding: 15px;
}
.appointment-form-date {
  display: flex;
  align-items: flex-start;
}
.appointment-form-date .data {
  display: flex;
  margin-bottom: 10px;
}
.appointment-form-date .data input {
  padding: 12.5px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  color: #51595e;
  font-weight: bold;
  font-family: var(--lato);
  cursor: pointer;
  margin-right: 15px;
  background: white !important;
}
.appointment-form-organization,
.appointment-form-location {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.appointment-form-upload,
.appointment-form-taskitem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}
.appointment-form-organization .icon,
.appointment-form-location .icon {
  margin-right: 22.5px;
  padding: 15px;
}
.appointment-form-upload .icon {
  margin-right: 30px;
  padding: 15px;
}
.appointment-form-organization .icon svg,
.appointment-form-location .icon svg {
  font-size: 28px;
  color: #446fa2;
}
.appointment-form-organization .data input,
.appointment-form-location .data input,
.appointment-form-upload .data input,
.appointment-form-taskitem .data input {
  padding: 15px;
  color: #51595e;
  font-family: var(--lato);
  font-size: 13px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
}
.appointment-form-organization .data,
.appointment-form-location .data,
.appointment-form-upload .data,
.appointment-form-taskitem .data {
  width: 100%;
}
.appointment-form-taskitem .data input {
  padding: 12.5px;
}
.appointment-form-upload .data {
  display: flex;
  flex-direction: column;
  position: relative;
}
.appointment-form-user {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.appointment-form-user .icon {
  margin-right: 25px;
  padding: 15px;
}
.appointment-form-user .data {
  width: 100%;
}
.appointment-form-user .data input {
  padding: 15px;
  width: 100%;
  color: #51595e;
  font-family: var(--lato);
  font-size: 13px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-weight: bold;
}
.appointment-form-upload .icon {
  margin-right: 22.5px;
  padding: 15px 22.5px;
}
.appointment-form-taskitem .icon {
  margin-right: 22.5px;
  padding: 15px 22.5px;
  padding-top: 7.5px;
}
.appointment-form-upload .data label {
  cursor: pointer;
  padding: 15px;
  color: #51595e;
  font-family: var(--lato);
  font-size: 13px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  font-weight: bold;
}
.appointment-form-submit button {
  cursor: pointer;
  background-color: #5ca127;
  font-size: 13px;
  margin-left: 87.5px;
}
.appointment-form-taskitem .data {
  display: flex;
  align-items: flex-start;
}
.appointment-form-taskitem .input-holder {
  display: flex;
  flex-direction: column;
}
.appointment-form-taskitem .input-holder input {
  margin-bottom: 5px;
}
.appointment-form-taskitem .data > div {
  margin-right: 15px;
}
.appointment-form-taskitem .data button.add-button {
  background: transparent;
  border: none;
  margin-top: 7.5px;
  cursor: pointer;
}
.appointment-form-taskitem .data button.add-button + button {
  padding: unset;
  margin-top: 6px;
  margin-left: 10px;
  color: #c0392b;
}

/* when search button is focused */
.appointment-form-organization .data.hide-dropdown .organization-list,
.appointment-form-user .data.hide-dropdown .organization-list,
.appointment-form-upload .data.hide-dropdown .organization-list {
  display: none;
}

/* responsive  */
@media screen and (max-width: 1600px) {
  span.rs__cell.rs__header span {
    font-size: 13px;
  }
  .appointment-form-date .data {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1400px) {
  .create-appointment-upper {
    flex-direction: column;
    gap: 15px;
  }
}
@media screen and (max-width: 1268px) {
}
@media screen and (max-width: 1024px) {
  .appointment-left {
    width: 87.5%;
    margin-right: unset;
    margin-bottom: 15px;
  }
  .appointment-right {
    width: 87.5%;
    margin-left: unset;
    margin-bottom: 75px;
    margin-left: 4%;
  }
  .appointment-main {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    flex-wrap: wrap;
   
  }
  span.rs__cell.rs__header span {
    font-size: 9px;
  }
  .appointment-form-date .data {
    flex-wrap: wrap;
  }

  /* custom class adding */
  /* input[type="datetime-local"] {
    position: relative;
    text-indent: 15px;
  }
  input[type="datetime-local"]:before {
    font-weight: 400;
    content: attr(class);
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  } */
  .create-appointment-upper {
    flex-direction: row;
    gap: unset;
  }
}

/* ipad air  */
@media screen and (max-width: 980px) {
  .appointment-left {
    margin-left: 7%;
  }
  .appointment-right {
    margin-left: 7%;
  }
}
/* ipad air  */

@media screen and (max-width: 900px) {
  .appointment-main {
    justify-content: flex-end;
  }
  .appointment-left {
    margin-left: unset;
  }
  .appointment-right {
    margin-left: unset;
  }
}

@media screen and (max-width: 768px) {
  .appointment-right {
    margin-bottom: 25px;
  }
  .create-appointment-head-button button {
    font-size: 11px;
    width: 156;
  }
}
@media screen and (max-width: 660px) {
  .create-appointment-upper {
    padding: 20px;
  }
}
@media screen and (max-width: 600px) {
  .appointment-header {
    height: 65px;
  }
  .appointment-left {
    width: 100%;
  }
  .appointment-main {
    justify-content: center;
    margin-top: 0;
     padding-bottom: 50px;
   
  }
  .create-appointment-lower {
    padding: 25px 5px;
  }
  .appointment-form-details .icon,
  .appointment-form-user .icon {
    margin-right: 7.5px;
  }
  .appointment-form-date .icon {
    margin-right: 10px;
  }
  .appointment-form-organization .icon,
  .appointment-form-location .icon,
  .appointment-form-upload .icon,
  .appointment-form-taskitem .icon {
    margin-right: 2.5px;
  }
  .appointment-form-submit button {
    margin-left: 65px;
  }
  .appointment-left {
    margin-bottom: 5px;
  }
  .css-m1a27h > div > div {
    display: flex;
  }
  .appointment-main button {
    font-size: 12px;
  }
  .appointment-right {
    width: 100%;
    margin-bottom: unset;
  }
  .card-container {
    width: 95%;
    margin: 0 auto;
  }
  .appointment-navigation {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 60px;
  }
  .appointment-form-organization .data input,
  .appointment-form-location .data input,
  .appointment-form-upload .data input,
  .appointment-form-taskitem .data input,
  .appointment-form-user .data input,
  .appointment-form-date .data input {
    font-weight: normal;
  }

  div.css-v1j55f {
    max-width: 100% !important;
    min-width: unset !important;
  }
  p.folder-div {
    align-items: flex-start !important;
  }
  .css-v1j55f span {
    white-space: break-spaces;
  }
  .css-v1j55f p {
    white-space: break-spaces;
  }
  .organization-list .data-list span {
    font-size: 12px;
    margin-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .organization-list .data-list > div {
    width: 65%;
  }
}
@media screen and (max-width: 540px) {
  .create-appointment-head-button button {
    font-size: 10px;
    width: 144px;
  }
}
@media screen and (max-width: 490px) {
  .create-appointment-upper {
    flex-direction: column;
    gap: 15px;
  }
}
@media screen and (max-width: 379px) {
  .appointment-schedule-upper {
    padding: 20px 15px 0px 15px;
  }
  .appointment-schedule-head-lover-button button {
    font-size: 11px;
  }
}
