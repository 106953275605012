.part-detail-parent {
  position: relative;
  height: 100%;
}

.part-detail-navigation {
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  height: 60px;
}

.part-detail-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}

.part-detail-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  overflow-y: auto;
}
.part-detail-left {
  width: 502px;
  max-height: 720px;
  background-color: white;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  overflow-y: auto;
 
}
.part-detail-left::-webkit-scrollbar {
  width: 3px; 
  
}

.part-detail-left::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px;

}

.part-detail-left::-webkit-scrollbar-thumb {
  background: #c1c1c1; /* Color of the scrollbar thumb */
  border-radius: 5px;
}


.part-detail-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 25px;
}
.right-insider {
  width: 666px;
  height: 300px;
  background-color: white;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
}
.left-header {
  text-align: left;
  font-weight: 700;
  font-family: "Montserrat";
  font-size: 18px;
  letter-spacing: 0px;
  color: #51595e;
  text-transform: capitalize;
  border-bottom: 2px solid #e1e1e1;
  padding: 20px;
}
.left-form {
  padding: 15px;
}
.left-footer {
  background: #5ca127 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  color: white;
  font: normal normal bold 12px/21px Lato;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  margin-top: 18px;
}
.left-footer p {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.left-footer p label {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.left-footer p span {
  padding-left: 10px;
}
.right-insider > p {
  text-align: left;
  padding: 20px;
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 700;
  letter-spacing: 0px;
  color: #51595e;
  text-transform: capitalize;
  border-bottom: 1px solid #e1e1e1;
  margin: 0;
}
.right-insider-inventory {
  text-align: left;
  padding:  0 20px;
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: 700;
  letter-spacing: 0px;
  color: #51595e;
  text-transform: capitalize;
  border-bottom: 1px solid #e1e1e1;
  margin: 0;
}
.part-detail-field {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
}
.part-detail-field label {
  text-align: left;

  font-weight: 700;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 0px;
  color: #51595e;
  flex: 1;
}
.part-detail-field textarea {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: left;
  padding: 12px;
  width: 304px;
  border: none;
  font-weight: 500;
  font-family: "Lato";
  font-size: 15px;
  letter-spacing: 0px;
  color: #51595e;
  flex: 2;
}
.part-detail-field input {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: left;
  padding: 12px;
  width: 304px;
  border: none;
  font-weight: 500;
  font-family: "Lato";
  font-size: 15px;
  letter-spacing: 0px;
  color: #51595e;
  flex: 2;
}
.linked-asset-header {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}
.header-box {
  background-color: #446fa2;
  padding: 2.5px 0;
  text-align: center;
  border: 1px solid white;
  font-size: 10px;
  font-weight: 500;
  font-family: "Montserrat";
  color: #ffffff;
  text-transform: uppercase;
}
.inventory-content {
  max-height: 225px;
  overflow-y: auto;
}
.linked-asset-content{
  max-height: 200px;
  overflow-y: auto;
}
.inventory-content-box {
  border: 1px solid white;
  width: 100%;
  padding: 5px 20px;
  margin-bottom: 2px;
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
.inventory-quantity {
  background-color: white;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  color: #51595e;
  font-size: 12px;
  font-style: italic;
  font-family: "Lato";
  text-align: center;
  /* width: 144px; */
  padding: 20px 10px ;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 1400px) {
  .part-detail-left {
    width: 480px;
  }
  .right-insider {
    width: 570px;
  }
}
@media (max-width: 1250px) {
  .part-detail-left {
    width: 450px;

  }
  .row-box {
   
    font-size: 13px; /* Optional: adjust font size */
  }
  .right-insider {
    width: 450px;
  }

  .part-detail-main {
    justify-content: flex-end;
  }
}
@media (max-width: 1050px) {
  .part-detail-left {
    width: 80%;
    height: auto; 
    max-height: none; 
    margin-left: 30px;
  }
  .right-insider {
    width: 100%;
  }
  .part-detail-right {
    flex-wrap: wrap;
    width: 80%;

    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  .part-detail-main {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .part-detail-left,
  .part-detail-right {
    width: 85%;
  }
  .part-detail-main {
    justify-content: flex-end;
  }
  .part-detail-right {
    margin: 20px 0;
  }
}
@media (max-width: 600px) {
  .part-detail-left,
  .part-detail-right {
    width: 100%;
    margin-left: 0;
  }
  .part-detail-right {
    margin-bottom: 100px;
  }
  .part-detail-main {
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  .part-detail-field input {
    width: 250px;
    font-size: 12px;
  }
  .part-detail-field textarea {
    width: 250px;
    font-size: 12px;
  }
  .part-detail-field label {
    font-size: 12px;
  }
}
.edit-part-detail{

    display: flex;
    justify-content: flex-start; 
     align-items: center;
     padding: 17px;
}
.submit-part-detail-btn{
 
    background-color: #599b26;
    font-size: 11px;
    font-family: "Montserrat";
    font-weight: 500;
    padding: 5px 10px;
    color: white;
    border: none;
    border-radius: 5px;
    text-transform: capitalize;
    margin-right: 10px;

}
.part-detail-field .part-detail-vendor-wrapper {
  position: relative; /* Ensures the dropdown is positioned relative to this wrapper */
}



.vendor-organization-list {
  position: absolute;
  top: 100%; /* Place the dropdown right below the input */
  left: 0;
  width: 100%; /* Match the width of the input field */
  max-height: 140px; /* Optional: limit height for scrolling */
  overflow-y: auto; /* Enable scrolling for long lists */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100; /* Ensure it appears above other elements */
}

.vendor-organization-list .data-list {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.vendor-organization-list .data-list:hover {
  background-color: #f5f5f5;
}

.vendor-organization-list img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.vendor-organization-list span {
  flex-grow: 1;
}

.part-detail-vendor-wrapper {
  position: relative; /* This ensures the dropdown is positioned relative to this container */
}

.vendor-organization-list {
  position: absolute; /* Position dropdown absolutely within the wrapper */
  top: 100%; /* Align just below the input */
  left: 0; /* Align with the left edge of the input */
  z-index: 1000; /* Ensure dropdown appears above other elements */
  background-color: #fff; /* Add background color */
  border: 1px solid #ccc; /* Add border for visibility */
  border-radius: 4px; /* Optional: Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  width: 100%; /* Match the input width */
  max-height: 200px; /* Limit height for large lists */
  overflow-y: auto; /* Enable scrolling if the content exceeds max height */
}

.data-list {
  padding: 10px; /* Add padding for each item */
  cursor: pointer; /* Show pointer to indicate clickable items */
}

.data-list:hover {
  background-color: #f0f0f0; /* Optional: Add hover effect */
}
.edit-vendor-wrapper {
  position: relative;
  /* width: 100%; */
}

/* .input-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
} */

/* .location-chip-with-quantity {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 5px 10px;
}

.location-chip {
  display: block;
  align-items: center;
  gap: 8px;
}

.chip-remove-btn {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.quantity-controls {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quantity-value {
  font-weight: bold;
} */
.part-detail-vendor-wrapper input {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: left;
  padding: 12px;
  width: 312px;
  border: none;
  font-weight: 500;
  font-family: "Lato";
  font-size: 15px;
  letter-spacing: 0px;
  color: #51595e;
  flex: 2 1;
}
@media(max-width:1400px){
  .part-detail-vendor-wrapper input {
    width:299px;
  }
}

@media(max-width:1250px){
  .part-detail-vendor-wrapper input {
    width:280px;
  }
  .edit-vendor-wrapper  input{
    width:280px !important;
  }
}

@media(max-width: 1050px) {
  /* Ensures that input fields wrap to the next line */
  .part-detail-field {
    display: flex;
    flex-wrap: wrap; /* Allow elements to wrap to the next line */
    flex-direction: column; /* Stack elements vertically */
    width: 100%; 
    align-items: flex-start;/* Ensure full width for the container */
  }

 
  .part-detail-field input,
  .part-detail-field textarea {
    width: 100%; 
    margin-bottom: 10px; 
  }
  .edit-vendor-wrapper {
   
    width: 100% ;
  }
  .part-detail-field .part-detail-vendor-wrapper {
    width: 100% ;
}
  .edit-vendor-wrapper input {
    width:100% !important;
  }
}


.row-box {
  flex: 1 1 33.4%; /* Default: 3 items per row */

 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}





@media (max-width: 768px) {
  .row-box {
    flex: 1 1 50%; /* 2 items per row on smaller screens */
    font-size: 14px; /* Optional: adjust font size */
  }
}

@media (max-width: 480px) {
  .row-box {
    flex: 1 1 100%; /* Stack items vertically on small screens */
    font-size: 12px; /* Optional: further reduce font size */
  }
}
