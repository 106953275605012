.chat-screen-profile > * {
  width: 100%;
}
.chat-screen-container {
  background: #ffffff;
  border-radius: 5px;
}
.chat-screen-upper {
  padding: 15px;
  border-bottom: 1px solid #0000001a;
}
.chat-screen-options button {
  border: unset;
  background: white;
}
.chat-screen-head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.chat-screen-profile h3 {
  font-family: var(--montserrat);
}
.chat-screen-profile p,
.chat-screen-profile b {
  font-family: var(--lato);
  font-size: 13px;
  color: #647589;
}
z .chat-screen-logo img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}
.chat-screen-head > div:first-child {
  display: flex;
}
.chat-screen-profile > * {
  margin: unset;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.chat-screen-profile {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
.chat-screen-options svg {
  font-size: 35px;
  color: #545454;
}
.chat-screen-options {
  display: flex;
  align-items: flex-end;
}
.chat-screen-options img {
  margin-left: 15px;
}
.chat-screen-logo {
  position: relative;
}
.chat-screen-status-dot {
  position: absolute;
  top: 5%;
  right: 5%;
  color: #87c823;
}
.chat-left .chat-screen-status-dot {
  top: 0;
  right: 0;
}
.chat-screen-lower {
  height: 675px;
}
.chat-screen-chatsection {
  height: 575px;
  overflow-y: scroll;
  margin-bottom: 5px;
  border-bottom: 1px solid #0000001a;
  overflow-x: hidden;
}
.chat-screen-inputsection {
  display: flex;
  height: 10vh;
  justify-content: space-evenly;
  align-items: center;
}
.chat-screen-input textarea {
  border: unset;
  width: 100%;
  background: #f5f5f5;
  font-family: var(--montserrat);
  outline: unset;
  resize: none;
}
.chat-screen-input {
  display: flex;
  width: 85%;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.chat-screen-send {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5cbb55;
  border-radius: 50%;
  padding: 12.5px;
  cursor: pointer;
}
.chat-screen-send:hover {
  background: #22a6b3;
}
.chat-screen-send img {
  width: 20px;
  height: 20px;
}

/* chatting section  */
.chat-thread {
  margin: 24px auto 0 auto;
  padding: 0 20px;
  list-style: none;
}

.chat-thread li {
  display: inline-block;
  padding: 10px 20px 18px 10px;
  margin: 0 7.5px 20px 7.5px;
  font-size: 14px;
  border-radius: 10px;
  background-color: #f5f5fb;
  font-family: var(--lato);
  max-width: 60%;
  min-width: 20%;
  position: relative;
  overflow-wrap: break-word;
}
.left-side-chat {
  display: flex;
  justify-content: flex-start;
}
.right-side-chat {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.right-side-chat li {
  background-color: #efefef;
}
.right-side-chat li b,
.left-side-chat li b {
  display: block;
  font-size: 12px;
  color: #51595e;
}
.chat-avatar img {
  width: 58px;
  height: 58px;
  border-radius: 50px;
  object-fit: cover;
}
ul.chat-thread span.timeconverted {
  font-size: 8px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.chat-screen-input label {
  /* cursor: pointer; */
  display: flex;
  align-items: center;
}
.chat-screen-input img {
  cursor: pointer;
  width: 22px;
  height: 20px;
}

.chat-screen-options svg,
.chat-screen-options img {
  cursor: pointer;
}
ul.chat-thread li img {
  max-width: 230px;
}
li.chat-media {
  padding: 5px 5px;
  padding-bottom: 15px;
}

.chat-date {
  margin: 10px 0;
  font-size: 14px;
  color: #647589;
  font-family: var(--lato);
  text-align: center;
}

.preview-image {
  position: relative;
}
.preview-image img {
  max-width: 50px;
  width: 100%;
}
.image-cancel {
  width: fit-content;
  border-radius: 50%;
  border: solid 1px gray;
  font-size: 10px;
  padding: 2.5px;
  position: absolute;
  right: -35%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 17.5px;
  cursor: pointer;
}
.video-chat-tile {
  background-color: #a3c1f5 !important;
}

a.video-link {
  color: black;
  cursor: pointer;
}
a.video-link:hover {
  color: gray;
}
.chat-screen-logo img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
  background-color: #f7f7f7;
  border-radius: 5px;
  opacity: 1;
  box-shadow: 0px 1px 3px #0000001c !important;
  color: #446fa2 !important;
  font: normal normal bold 13px/21px Lato;
  letter-spacing: 0px;
  opacity: 1;
}
.MuiDivider-root.MuiDivider-fullWidth.css-9mgopn-MuiDivider-root {
  margin: 0px !important;
}
.chat-screen-menuItem {
  display: flex;
  gap: 10px;
  color: #446fa2 !important;
}
.chat-screen-org-visit-btn {
  width: 117px;
  height: 31px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #599b26 !important;
  border-radius: 16px 5px 5px 16px;
  font: normal normal 600 11px/19px var(--montserrat);
  color: #5ca127;
  text-transform: uppercase;
  display: flex;
  column-gap: 15px;
  align-items: center;
  padding: 0;
  margin-right: calc(15px + 6px);
  margin-bottom: 2px;
}
.chat-screen-org-visit-btn img {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  display: block;
  object-fit: contain;
  margin-left: 0px !important;
}
.chat-file {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.chat-file:hover {
  text-decoration: underline;
}
.chat-file-image {
  width: 25px;
  padding-right: 5px;
}
.chat-file-text {
  color: #446fa2;
  font-size: 14px;
  word-break: break-all;
  margin-right: 20px;
}

.chat-file-option {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  padding-right: 3px !important;
  z-index: 5;
  background: #efefef !important;
  border-radius: 0 !important;
}
.file-download-text {
  display: flex;
  text-decoration: none;
  color: #446fa2;
}
.chat-remove-group-message {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-style: italic;
  color: #51595e;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .chat-screen-options {
    flex-direction: column;
    gap: 10px;
  }
  .chat-screen-org-visit-btn {
    margin-right: 0;
  }
  .chat-screen-options > div {
    display: flex;
    align-items: flex-end;
  }
  .chat-screen-options button {
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .chat-screen-options {
    flex-direction: row;
  }
  .chat-screen-options svg {
    font-size: 25px;
  }
  .chat-screen-org-visit-btn {
    font-size: 11px;
    width: 105px;
    height: 25px;
  }
  .chat-screen-org-visit-btn img {
    width: 19px;
    height: 19px;
  }
  .chat-screen-options > div {
    gap: 15px;
  }
}
@media only screen and (max-width: 470px) {
  .chat-screen-options {
    flex-wrap: wrap;
  }
  ul.chat-thread li img {
    max-width: 160px !important;
  }
}
