.file_explorer_folder > p {
  text-align: center;
}
.file_explorer_header {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 75px;
}
.file_explorer_navigation {
  position: fixed;
  z-index: 1;
}
.file_explorer_main {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.file_explorer_left {
  width: 35%;
  margin-right: 1%;
}
.file_explorer_right {
  width: 55%;
  margin-left: 1%;
}
.file_explorer_wrapper {
  width: 100%;
  height: max-content;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 5px 40px #00000005;
}
.file_explorer_upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom: solid 2px var(--border-color);
  font-family: var(--montserrat);
}
.file_explorer_head {
  font-family: var(--montserrat);
  font-weight: bold;
  color: #51595e;
  font-size: var(--primary-heading);
}
.file_explorer_head_right_container {
  display: flex;
  gap: 15px;
  align-items: center;
}
.file_explorer_head_right_container_button {
  font-size: 12px !important;
}
.file_explorer_org {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  border-bottom: solid 2px var(--border-color);
}
.file_explorer-logo {
  display: flex;
  align-items: center;
}
.file_explorer-logo img {
  border-radius: 50%;
  object-fit: contain;
}
.file_explorer_bio {
  width: 100%;
  margin-left: 20px;
}
.file_explorer_bio h3 {
  margin-top: unset;
  margin-bottom: 5px;
  font-family: var(--lato);
  font-size: var(--primary-text);
  font-weight: bolder;
  text-transform: capitalize;
}
.file_explorer_bio p {
  margin: unset;
  margin-top: 5px;
  color: #51595e;
  font-family: var(--lato);
}
.file_explorer_height {
  height: 78vh;
  display: flex;
  flex-direction: column;
}
.file_explorer_single_folder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
  align-content: flex-start;
}
button.file_explorer_dots {
  position: absolute;
  top: 2.5px;
  right: 2.5px;
}
.file_explorer_options {
  display: flex;
  justify-content: space-between;
  padding: 5px 25px;
  background-color: #5ca127;
  height: 55px;
}
.file_explorer_options > div {
  display: flex;
  border: solid 1.5px white;
  padding-right: 10px;
  width: 17%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.file_explorer_options label {
  display: flex;
  cursor: pointer;
}
.file_explorer_options form.sharefolder-organization label {
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 0;
  background-color: #ecf0f1;
}
.file_explorer_options form.sharefolder-organization label:hover {
  background-color: #bdc3c7;
}
.file_explorer_options > div:first-child {
  width: 25%;
}
.file_explorer_options > div:last-child {
  width: 20%;
}
.file_explorer_options p {
  font-size: 12px;
  font-family: var(--lato);
  color: white;
  margin-left: 10px;
}
.visit-icon button {
  font-size: 10px;
  white-space: nowrap;
}
.file_explorer_left .file_explorer_lower {
  height: 80vh;
  overflow-y: scroll;
}
.bookmark-container {
  font-size: 14px;
  font-family: var(--lato);
  /* font-weight: bold; */
  background: #f5f5f5;
  padding: 5px 15px;
  white-space: nowrap;
  overflow: scroll;
  text-transform: capitalize;
  align-items: center;
}
.bookmark-container::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.bookmark-container span {
  cursor: pointer;
  color: #2ba5f2;
}
.bookmark-container span:hover {
  color: #51595e;
  font-weight: normal;
}
.file_explorer_search {
  position: relative;
}
.file_explorer_search .flex {
  display: flex;
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}
.file_explorer_search .organization-list {
  position: absolute;
  background: whitesmoke;
  z-index: 1;
  border-radius: 5px;
  width: 505px;
  max-height: 350px;
  overflow-y: scroll;
  right: 0;
  padding: 5px 0;
}
.file_explorer_search .organization-list svg {
  font-size: 35px;
  margin-right: 10px;
}
.file_explorer_search .organization-list > div {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  padding: 0 5px;
  cursor: pointer;
}
.file_explorer_search .organization-list > div:hover {
  background-color: #e4c4c4;
}
.file_explorer_search .multiple-line-text {
  font-size: 12px;
}
.file_explorer_search .multiple-line-text * {
  word-break: break-all;
}
.file_explorer_search .organization-list > div img {
  width: 40px;
  margin-right: 5px;
}
.file_explorer_search.hide-dropdown .organization-list,
.file_explorer_search.hide-dropdown .orgListAlert {
  display: none;
}
.file_explorer_search .orgListAlert {
  background: whitesmoke;
  display: flex;
  position: absolute;
  height: 75px;
  align-items: center;
  padding: 0 50px;
  z-index: 1;
  border-radius: 5px;
  width: 300px;
  border: solid 1px;
}
.file_explorer_search svg {
  color: #51595e;
}
.file_explorer_search input {
  border: unset;
  color: #51595e;
  font-size: 12px;
  font-family: var(--montserrat);
  outline: none;
}

/* responsive  */
/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  div.directory-folder-single > div p {
    font-size: 16px;
  }
  div.directory-file-text {
    font-family: var(--montserrat);
    font-size: 9px;
  }
  .file_explorer_main {
    width: 100%;
    justify-content: flex-end;
  }
  .file_explorer_container p {
    font-size: 14px !important;
  }
  .file_explorer_container .file_explorer_options p {
    font-size: 10px !important;
  }
  .file_explorer_search .organization-list {
    width: 420px;
  }
  .file_explorer_search .organization-list {
    width: 420px;
  }
}
@media screen and (max-width: 1300px) {
  .file_explorer_search .organization-list {
    width: 310px;
  }
}

@media screen and (max-width: 1200px) {
  .file_explorer_main {
    justify-content: flex-end;
  }
  .file_explorer_right {
    width: 58%;
  }
}
@media screen and (max-width: 1024px) {
  .file_explorer_main {
    flex-wrap: wrap;
  }
  .file_explorer_left,
  .file_explorer_right {
    width: 87.5%;
    margin: unset;
  }
  .file_explorer_right .file_explorer_wrapper {
    margin-top: 25px;
    height: fit-content;
  }
  .file_explorer_left .file_explorer_lower {
    height: unset;
  }
  .file_explorer_folder {
    height: 200px;
    overflow-y: scroll;
  }
  .file_explorer_right .file_explorer_height {
    height: 500px;
    overflow-y: scroll;
  }
  .file_explorer_search .organization-list {
    width: 400px;
    max-height: 300px;
  }
}
@media screen and (max-width: 600px) {
  .file_explorer_container {
    height: 100%;
  }
  .file_explorer_header {
    height: 65px;
  }
  .file_explorer_navigation {
    height: 60px;
  }
  .file_explorer_navigation {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
  }
  .file_explorer_left,
  .file_explorer_right {
    width: 100%;
    margin: unset;
    height: fit-content;
  }
  .file_explorer_main {
   
    overflow-y: scroll;
    justify-content: center;
    margin: unset;
    width: 100%;
    align-content: flex-start;
    padding-bottom: 60px;
  }
  div.file_explorer_folder_wrapper {
    padding: 15px;
  }
  div.file_explorer_folder_wrapper > div:first-child {
    flex: 0 0 55%;
  }
  div.file_explorer_folder_wrapper > div:first-child img {
    width: 50px;
  }
  .file_explorer_container .file_explorer_options p {
    margin: unset;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .file_explorer_options > div {
    flex-direction: column;
    text-align: center;
    padding: 2.5px 0;
  }
  .file_explorer_options label {
    flex-direction: column;
    align-items: center;
  }
  .file_explorer_left .file_explorer_lower {
    height: unset;
  }
  .file_explorer_options img {
    width: 15px;
    height: 12.5px;
    margin-bottom: 5px;
  }
  .file_explorer_container .file_explorer_options p {
    font-size: 9px !important;
  }
  .file_explorer_options {
    padding: 5px;
  }
  .file_explorer_org {
    padding: 5px 15px;
  }
  .file_explorer_upper {
    padding: 15px;
  }
  .file_explorer_folder {
    height: 150px;
    overflow-y: scroll;
  }
  .file_explorer_height {
    height: 45vh;
    overflow-y: scroll;
  }
  .qr-title + img {
    width: 75px;
    height: 75px;
  }
  form.sharefolder-organization label.sharefolder-list {
    flex-direction: row;
    margin: 5px 0;
  }
  .sharefolder-popup span.title {
    margin: unset;
  }
  .sharefolder-popup span.title {
    margin-bottom: 15px;
  }
  .folderseting-popup {
    width: 90% !important;
    padding: 20px 15px !important;
  }
  .team .team-info p {
    font-size: 14px !important;
  }
  .folderseting-container .folder-details p {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  div.directory-folder-single > div img {
    width: 50px;
  }
  div.directory-file-icon img {
    height: 28px;
  }
  div.directory-folder-single > div p {
    margin: unset;
    margin-left: 10px;
  }
  .file_explorer_single_folder {
    padding: 10px;
  }
  .file_explorer_right .file_explorer_wrapper {
    margin-top: 10px;
  }
  .file_explorer_search .organization-list {
    width: 325px;
    max-height: 200px;
  }
  .file_explorer_head_right_container_button {
    font-size: 11px !important;
    padding: 7px 10px 3px 10px !important;
  }
}
@media screen and (max-width: 420px) {
  .file_explorer_upper_bookmark {
    flex-direction: column;
    gap: 7px;
  }
}
@media screen and (max-width: 359px) {
  .visit-icon button {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 346px) {
  .visit-icon button {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 330px) {
  .file_explorer_search .organization-list {
    width: 290px;
    max-height: 200px;
  }
}
