.login-upper {
  height: 350px;
}
.login-below label {
  font-family: var(--montserrat);
}
.login-logo {
  background: #a3c1f5;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
  height: 300px;
  position: relative;
}
.login-logo img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 15%);
}
.login-below {
  width: 32.5%;
  margin: 0 auto;
}
.login-below form > div {
  margin: 25px 0;
  margin-bottom: 0;
}
.login-below input {
  padding: 10px 0;
}
.login-below button {
  padding: 10px 0;
  font-family: var(--montserrat);
}
.login-below svg,
.login-below div {
  color: black;
}
.forget-account-link {
  width: fit-content;
  margin: 10px auto;
  margin-bottom: 0;
  font-family: var(--montserrat);
  color: #446fa2;
}
.forget-account-link a {
  color: rgb(68, 111, 162);
  text-decoration: unset;
}
.login-help {
  position: absolute;
  top: 3%;
  z-index: 5;
  right: 1%;
}
.login-help button svg {
  color: #446fa2;
  font-size: 50px;
}
.divider {
  width: 32.5%;
  margin: 15px auto;
}
.create-account-link {
  width: 32.5%;
  margin: 0 auto;
}
.create-account-link button {
  font-family: var(--montserrat);
}
.login-logo img {
  width: 250px;
}

/* responsive  */

@media screen and (max-width: 1600px) {
  .login-help button svg {
    font-size: 40px;
  }
}

@media screen and (max-width: 1300px) {
  .login-below {
    width: 50%;
  }
  .divider {
    width: 50%;
  }
  .create-account-link {
    width: 50%;
  }
  .login-logo img {
    width: 200px;
  }
  .login-logo {
    height: 240px;
  }
  .login-upper {
    height: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .login-logo img {
    width: 165px;
  }
  .login-upper {
    height: 250px;
  }
  .login-logo {
    height: 200px;
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
  }
}

@media screen and (max-width: 768px) {
  .login-below {
    width: 80%;
  }
  .divider {
    width: 80%;
  }
  .create-account-link {
    width: 80%;
  }
}

@media screen and (max-width: 600px) {
  .login-below {
    width: 90%;
  }
  .divider {
    width: 90%;
  }
  .create-account-link {
    width: 90%;
  }
  .login-logo {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .login-help {
    top: 1%;
  }
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
