.asset-management-navigation {
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  height: 60px;
}
.asset-management-parent {
  position: relative;
  height: 100%;
}
.asset-management-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.asset-management-main {
  width: 90%;

  margin: 0 auto;
  text-align: center;
  margin-top: 35px;
  overflow-y: auto;
}
.asset-management-main h1 {
  color: #707070;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  line-height: 37px;

  font-style: "normal";
  letter-spacing: normal;
}
.asset-management-main-box {
  background-color: #fff;
  width: 1200px;
  height: 600px;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  opacity: 1;
  padding-top: 2px;
  margin: 0 auto;
  margin-top: 30px;
}
.createbutton {
  height: 70px;
}
.createbutton button {
  color: white;
  background-color: #5ca127;
  border-radius: 5px;
  opacity: 1;
  width: 150px;
  height: 35px;
  border: none;
  text-transform: Uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 18px;
}
.show-asset-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 25px;
}
.asset-box {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #70707021;
  opacity: 1;
  width: 565px;
  height: 427px;
overflow: auto;
overflow-x: auto;
  display: flex;
  flex-direction: column;
}
.asset-box {
  position: relative;
  /* Your other styles */
}
.grey-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(225, 225, 225,0.5); /* Semi-transparent grey overlay */
  pointer-events: none; /* Ensure overlay doesn’t interfere with clicks */
  z-index: 1;
}
.hover-image {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px; /* Optional: Adds spacing from the edges */
  width: 80px; /* Adjust size as needed */
  
  z-index: 2; /* Ensures it appears above any overlay if needed */
}
.asset-box-top,
.assetm-head-row {
  background-color: #446fa2;
  color: #fff;


}

.asset-box-top p {
  margin: 0;
  padding: 10px;
  font-weight: 500;
  font-size: 15px;
  font-family: "Lato";
}
.asset-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.asset-container {
  overflow-x: auto;
}
.assetm-head-row {
  border: 1px solid #fff;
  padding: 7px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Montserrat";
  /* flex-grow: 1;
  flex-basis: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* word-break: break-word; */
  width: 400px !important;
}

.asset-box-bottom {
  max-height: 400px;
  /* overflow-y: auto;
  overflow-x: auto; */
}
 
.asset-box-bottom::-webkit-scrollbar {
  width: 4px;
}
 
.asset-box-bottom::-webkit-scrollbar-track {
  background: #f5f5f5;
}
 
.asset-box-bottom::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
}
.asset-content-wrapper {
  display: flex;
  justify-content: center;
  /* align-items: stretch;
  overflow-x: auto; */
  background-color: #f5f5f5;
}

.asset-content-row {
  padding: 7px;

  font-size: 10px;
  font-weight: 600;
  font-family: "Montserrat";
  cursor: pointer;
  color: #51595e;
  border: 2px solid white;
 padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-break: break-word;
  width: 220px; 
  height: 35px;
}

.empty-assets {
  font-size: 16px;
  font-family: "Lato";
  color: #51595e;
  font-weight: bold;
  margin: 0;
}
.empty-assets-container >img {
  width: 64px;
  height: 64px;
  margin-top: 50px;
}

.asset-search {
  position: relative;
  width: 100%;
  max-width: 350px;
  padding: 4px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.asset-search:focus-within {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3); /* Add shadow around container on focus */
}

.asset-search input {
  width: 100%;
  padding: 10px 40px 10px 10px; /* Leave space for the icon */
  font-size: 16px;
  border: none; /* Remove inner input border */
  outline: none;
  border-radius: 5px;
}

.search-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  font-size: 18px;
  transition: color 0.3s;
}

.asset-search:focus-within .search-icon {
  color: #007bff; /* Optional: Change icon color on focus */
}
@media (max-width: 1400px) {
  .asset-management-main-box {
  
    width: 1100px;
  }
  .asset-box{
    width: 520px;
  }
}
@media (max-width: 1250px) {
  .asset-management-main-box {
    width: 85%; /* Adjust width for smaller screens */
    height: auto; /* Allow height to adjust based on content */
    margin-top: 20px;
    overflow-y: auto;
    margin-bottom: 100px;
  }

  .show-asset-boxes {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .asset-box {
    width: 95%;
    /* height: 300px; */
    margin-bottom: 20px;
    overflow-y: auto;
    overflow-x: auto;
  }
  .assetm-head-row {
    padding: 7px;
  }
}
@media (max-width: 768px) {
  .asset-management-main-box {
    width: 80%;
  }

  .asset-box {
    width: 100%;

    margin-bottom: 20px;
  }

  .show-asset-boxes {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
}

@media (max-width: 600px) {
  .asset-management-main-box {
    width: 100%; /* Adjust width for smaller screens */
    height: auto; /* Allow height to adjust based on content */
  }

  .assetm-head-row {
    padding: 7px;
  }
  .asset-management-main {
    width: 100%;
  }
}

.createbutton .disabled-button {
  background-color: #d3d3d3;
  color: #000;
  cursor: not-allowed;
}
.header-bar-asset {
  margin: 15px 20px 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
}






@media screen and (max-width: 850px) {
  .asset-box {
    overflow-x: scroll; 
    
  }
  /* .asset-box {
    overflow-x: auto;
    width: 100%; 
  } */
  .asset-box-wrapper, .asset-box-bottom {
    min-width: 880px; 
    
  }

  .asset-box::-webkit-scrollbar {
    height: 4px; 
  }

  .asset-box::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
  }
  .assetm-head-row,
  .asset-content-row {
  
    min-width: 220px;
  }
  .asset-box-top{
    min-width: 880px; 
  }
}



