.directory-file-container {
  width: 47.5%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 12.5px 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  margin-bottom: 25px;
}
button.directory-file-options,
button.directory-folder-options {
  position: absolute;
  top: 2.5%;
  right: 2.5%;
}
.directory-file-text {
  font-family: var(--montserrat);
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
}
.directory-file-icon svg {
  font-size: 34px;
}
.directory-folder-single {
  position: relative;
  width: 47.5%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #ececec;
  margin-bottom: 25px;
  padding: 5px 0;
  cursor: pointer;
  height: fit-content;
}
.directory-folder-single > div {
  display: flex;
  align-items: center;
}
.directory-folder-single > div p {
  font-family: var(--lato);
  color: #51595e;
  font-weight: bold;
  font-size: var(--primary-heading);
  margin-left: 10px;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.directory-folder-single-aliasname {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.directory-folder-single-aliasname span {
  font-family: var(--lato);
  color: #51595e;
  font-weight: bold;
  font-size: var(--secondary-text);
  margin-left: 10px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.directory-folder-single-aliasname span:last-child {
  font-size: 11px !important;
  font-weight: normal;
}
.directory-folder-single > div img {
  width: 75px;
}
.directory-file-icon img {
  height: 36px;
  object-fit: contain;
}

/* dropdown-menu  */
button.directory-file-options img,
button.directory-folder-options img {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: -35%;
  right: 75%;
  z-index: 1;
}
.dropdown-menu a {
  text-decoration: none;
  color: unset;
}
.dropdown-menu ul {
  list-style-type: none;
  padding: 0%;
  margin: 0;
}
.dropdown-menu ul li {
  background: #ffffff no-repeat;
  box-shadow: 0px 3px 6px #00000033;
  border-radius: 2.5px;
  font-size: 12px;
  font-family: var(--lato);
  color: #606060;
  width: 100px;
  height: 30px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.open-document-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.dropdown-menu.hide-dropdown ul {
  display: none;
}

/* responsive  */

@media screen and (max-width: 1200px) {
  .directory-folder-single > div p {
    font-size: var(--primary-text);
  }
}
@media screen and (max-width: 850px) {
}
@media screen and (max-width: 600px) {
  .directory-folder-single,
  .directory-file-container {
    width: 100%;
    margin-bottom: 10px;
    padding: 7.5px 10px;
  }
}
