.header-container {
  display: flex;
  align-items: center;
  background: #a3c1f5;
  height: 75px;
  position: fixed;
  width: 100%;
  top: 0;
}
.result-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
}
.header-logo {
  flex-basis: 20%;
  display: flex;
  margin-left: 50px;
  justify-content: space-between;
}
.header-search {
  flex-basis: 50%;
  border-radius: 5px;
  position: relative;
  margin-left: 25px;
}
.header-search input {
  height: 10px;
}
.header-search label {
  font-size: 16px;
  color: #4c76a7;
  top: -5px;
  font-family: var(--montserrat);
}
.header-search svg {
  color: #4c76a7;
  cursor: pointer;
}
.search-bar-container div {
  background-color: #e4edfc;
  padding: unset;
}
.header-search div::before {
  border: unset;
}
.header-options {
  display: flex;
  flex-basis: 25%;
  justify-content: flex-end;
}
.header-options > div button {
  margin: 0 15px;
  color: #446fa2;
}
.header-logo img {
  width: 33px;
  height: 41px;
  object-fit: contain;
  cursor: pointer;
  /* margin-right: 25px; */
  padding-top: 5px;

}
.logo-menu {
  background-color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  
}
.logo-menubar{
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-icon{
  font-size: 16px !important;

  color:#4671a4;
}
.logo-menubar span{
  font-size: 12px;
  font-weight: 900;
  color:#446fa2;
  font-family:"lato"
}
.header-options svg {
  font-size: 35px;
}
.mobile-search {
  display: none;
  background: #e4edfc;
}
.search-bar-container {
  width: 100%;
  display: flex;
  background: #e4edfc;
  position: relative;
  border-radius: 5px;
}
p.no-data {
  text-align: center;
  color: black;
  font-weight: bold;
}
.result-container {
  position: absolute;
  background: white;
  width: 100%;
  margin-top: 5px;
  border-radius: 5px;
  max-height: 250px;
  overflow-y: scroll;
}
.result-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border: solid 1px #d7d7d7;
  cursor: pointer;
}
.result-text > * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.result-text span {
  display: block;
  color: #647589;
  font-size: 14px;
  font-family: var(--lato);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.result-wrapper > div {
  display: flex;
  align-items: center;
}
.result-text {
  margin-left: 15px;
  overflow: hidden;
  width: 100%;
}
.result-wrapper > div:first-child {
  flex: 0 0 55%;
  width: 100%;
  overflow: hidden;
}
.result-button {
  justify-content: flex-end;
  flex: 0 0 17.5%;
  white-space: nowrap;
}
.result-button button {
  font-family: var(--montserrat);
  font-weight: bold;
  font-size: 10px;
}
.header-notification-badge {
  position: relative;
  display: flex;
}
.header-notification-counter {
  position: absolute;
  top: -5px;
  left: 18px;
  background: #c23616;
  padding: 2.5px;
  border-radius: 50%;
  min-width: 18px;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.result-button button {
  display: flex;
  flex-direction: column;
}
.result-logo {
  display: flex;
}
.header-search.hide-dropdown .result-container {
  display: none;
}
.header-credit-storage-container {
  display: flex;
  gap: 15px;
}
.credits-icon-header,
.storage-icon-header {
  background: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
}
.credits-icon-header span,
.storage-icon-header span {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #446fa2;
  font-family: var(--lato);
  font-size: 14px;
}
.credits-icon-header svg {
  color: white;
  background: linear-gradient(180deg, #ffe21e, #d8be10);
  border-radius: 50%;
  margin-right: 5px;
}
.header-storage-pie-chart:hover {
  cursor: pointer !important;
}
.headers-hamburger-items {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -3px;
}
.headers-hamburger-items div {
  background-color: #a3c1f5;
  padding: 3px 10px;
}
.headers-hamburger-items div:last-child {
  border-radius: 0 0 5px 5px;
}

.header-portfolio-icon {
  position: relative;
  display: flex;
}
.header-portfolio-icon img {
  height: 35px;
  width: 30px;
}

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .result-button button {
    font-size: 8px;
  }
  .result-wrapper {
    padding: 10px;
  }
  .result-text h3 {
    font-size: 16px;
  }
  .result-text span {
    font-size: 12px;
  }
}

@media screen and (max-width: 1300px) {
}
@media screen and (max-width: 1024px) {
  .result-container {
    width: 140%;
  }
}
@media screen and (max-width: 920px) {
  .header-logo {
    margin-left: 20px;
  }
  .desktop-search {
    display: none;
  }
  .mobile-search {
    display: block;
  }
  .header-search {
    margin-left: 0;
  }
  .header-search-icon {
    display: flex !important;
    margin-right: 10px;
  }
  .header-search-icon img {
    width: 28px;
  }
  .header-options {
    margin-right: 15px;
  }
  .header-options > div button {
    margin: 0;
  }
  /* icon search bar */
  .header-container {
    position: relative;
    justify-content: space-between;
  }
  .mobile-search {
    position: absolute;
    width: 100%;
    /* margin-left: 50%;
    transform: translate(-50%, 0); */
    bottom: -38px;
  }
  .header-container.hide-dropdown .mobile-search {
    display: none;
  }
  .result-wrapper > div:first-child {
    flex: 0 0 74%;
  }
  .result-button {
    flex: unset;
    width: max-content;
  }
  .result-container {
    width: 100%;
    margin-top: unset;
  }
  .result-wrapper {
    padding: 10px 20px;
  }
  .search-bar-container {
    padding-right: 30px;
  }
}

@media screen and (max-width: 810px) {
}

@media screen and (max-width: 768px) {
  .result-text {
    margin-left: 10px;
  }
  .result-button {
    margin: 10px 0;
  }
}
@media screen and (max-width: 676px) {
}
@media screen and (max-width: 600px) {
  .header-options svg {
    font-size: 25px;
  }
  .logo-menu {
    margin-right:5px ;
    width: 50px;
    height: 50px;
  }
  .header-logo img {
    margin-right: 0;
    padding-left: 4px;
  }
  .logo-menubar span {
    font-size: 10px;
  }
  .header-logo {
    flex-basis: 15%;
  }
 
  .header-options {
    justify-content: space-around;
    flex-basis: 0%;
  }
  .header-options > div button {
    margin: unset;
    color: #446fa2;
    padding: 0;
  }
  .header-search label {
    font-size: 9px;
  }
  .header-search input {
    height: 5px;
    font-family: var(--montserrat);
    font-weight: bold;
    font-size: 14px;
  }
  .header-container {
    height: 65px;
  }
  .result-logo img {
    width: 54px;
    height: 54px;
    object-fit: contain;
  }
  .result-wrapper {
    padding: 10px 15px;
  }
  .header-options svg {
    font-size: 30px;
  }
  .header-search-icon img {
    width: 23px;
  }
  .header-options > div {
    margin-right: 5px;
  }
  /* .header-options > div:hover {
    background: white;
    border-radius: 5px;
  } */
  .result-container {
    margin-top: unset;
  }
  .search-bar-container {
    padding-right: 20px;
  }
}
@media screen and (max-width: 540px) {
}
@media screen and (max-width: 500px) {
  .header-options {
    margin-right: unset;
  }
  .headers-hamburger-items.hide-dropdown {
    display: none;
  }
}
@media screen and (max-width: 480px) {
}
@media screen and (max-width: 440px) {
  .header-logo {
    margin-left: 10px;
  }

  .header-credit-storage-container {
    gap: 10px;
  }
  .credits-icon-header span,
  .storage-icon-header span {
    font-size: 11px;
  }
}
@media screen and (max-width: 425px) {
  .result-wrapper {
    display: flex;
    flex-direction: column;
  }
  .result-button {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .header-logo {
    margin-left: 5px;
  }
  .header-logo img {
    margin-right: 5px;
  }
  .header-credit-storage-container {
    gap: 5px;
  }
  .credits-icon-header,
  .storage-icon-header {
    padding: 0 8px;
    margin-right: 3px;
  }
  
}
/* @media screen and (max-width: 370px) {
  .header-container {
    justify-content: center;
  }
  .header-logo {
    margin: unset;
    justify-content: space-around;
    width: 100%;
    flex-basis: unset;
  }
} */

@media screen and (max-width: 360px) {
}
@media screen and (max-width: 340px) {
}
