.logo-popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-popup-container > div {
  width: 90%;
  max-width: 767px;
  height: auto;
  max-height: 80%;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 0px 20px 25px 20px;
  overflow-y: auto;
}

.logo-popup-close {
  float: right;
}

.logo-popup-card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-width: 342px;
  height: 239px;
  border: 1px solid #4671a4;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  justify-content: center;
  transition: background-color 0.3s ease;
}
.logo-popup-card .hover-image {
  width: 45px;
  height: 45px;
  position: relative;
  top: -25%;
  left: 73%;
}
.logo-popup-card:hover {
  background-color: #ececec;
}

.logo-card-image {
  width: 163px !important;
  height: 163px !important;


}

.logo-popup-head {
  width: auto;
  height: auto;
  text-align: center;
  font: bold 20px/21px "Lato", sans-serif;
  color: #446fa2;
  text-transform: capitalize;
  opacity: 1;
  z-index: 10;
}

.logo-popup-top-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
}

.logo-popup-image {
  padding: 10px 13.5px;
  width: 64px;
  height: 64px;
  border: 1px solid #446fa2;
  border-radius: 50%;
  margin-right: 13px;
}

.logo-popup-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.heading {
  font-family: "Montserrat";
  font-size: 18px;
  color: #446fa2;
  font-weight: 700;
  padding-top: 10px;
}
@media only screen and (max-width: 845px) {
  .logo-popup-container > div {
    width: 80%;
    padding: 15px;
  }
  .logo-popup-card .hover-image {
    width: 40px;
    height: 40px;
    position: relative;
    bottom:56px;
    left:80%
  
  }
  .logo-popup-head {
    font-size: 18px;
  }

  .logo-popup-card {
    width: 80%;
    max-width: 300px;
    height: auto;
    padding: 20px;
    margin-bottom: 12px;
    margin-right:8px
  }

  .logo-card-image {
    width: 125px !important;
    height: 125px !important;
  }
}
/* Responsive Styles */
@media only screen and (max-width: 720px) {
  .logo-popup-container > div {
    width: 80%;
    padding: 15px;
  }

  .logo-popup-head {
    font-size: 18px;
  }
  .logo-popup-card .hover-image {
    width: 40px;
    height: 40px;
    position: relative;
    bottom:60px;
    left:82%
  
  }
  .logo-popup-card {
    width: 80%;
    max-width: 300px;
    height: auto;
    padding: 20px;
    margin-bottom: 12px;
  }

  .logo-card-image {
    width: 130px !important;
    height: 130px !important;
  }
}

@media only screen and (max-width: 480px) {
  .logo-popup-container > div {
    width: 90%;
  }

  .logo-popup-head {
    font-size: 16px;
  }

  .logo-popup-card {
    width: 100%;
    max-width: 280px;
    margin-bottom: 12px;
  }

  .logo-popup-body {
    flex-direction: column;
    align-items: center;
  }

  .logo-card-image {
    width: 100px !important;
    height: 100px !important;
    margin-right:40px !important;
  }

  .logo-popup-image {
    width: 50px;
    height: 50px;
    padding: 3px 6.5px;
    
  }
  .logo-popup-card .hover-image {
    width: 40px;
    height: 40px;
    position: relative;
    bottom:40px;
  left:190px
  
  }
}
