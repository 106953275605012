.asset-details-navigation {
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  height: 60px;
}
.asset-details-parent {
  position: relative;
  height: 100%;
}
.asset-details-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.asset-details-content {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
  overflow-y: auto;
}
.asset-info-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 120px;
}
.my-asset-details {
  background-color: white;
  margin: 20px;
margin-bottom:30px;
  border-radius: 5px;
  height: 580px;
  width: 502px;
}
.asset-details-left-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 40px;
  border-bottom: 1px solid #e1e1e1;
}
.edit-part {
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-button {
  border: none;
  color: #446fa2;
  font-size: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 6px;
}
.row-center {
  flex-direction: column;
  align-items: center;
}

.top-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 10px 0px;
}

.service-request {
  padding: 8px 12px;
  color: white;
  background-color: #599b26;
  font-size: 11px;
  font-family: "Montserrat";
  border: none;
  border: 1px solid #599b26;
  border-radius: 5px;
  font-weight: 500;
}
.create-folder {
  padding: 8px 12px;
  color: white;
  background-color: #599b26;
  font-size: 11px;
  font-family: "Montserrat";
  border: 1px solid #599b26;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 10px;
}
.go-back-btn {
  margin: 10px 0px 0px 20px;
}
.edit-part h2 {
  font-weight: 700;
  font-family: "Montserrat";
  font-size: 18px;
  color: #51595e;
}

.asset-info {
  padding: 18px 25px;
}
.asset-buttons {
  padding: 10px 5px;
  background-color: #5ca127;
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 25px;
}

.back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #5ca127;
  border-radius: 5px;

  justify-content: space-between;
  padding: 0 15px;
  font-size: 12px;
  border: none;
  color: white;
  margin: 10px 10px 0 25px;
  width: fit-content;
}
.asset_single_folder {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns per row */
  gap: 20px; /* Spacing between grid items */
  padding: 20px; /* Padding inside the container */
  height: 100%; /* Full height of the container */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}


.assets-container-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.assets-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  transition: transform 0.2s ease-in-out;
}

/* .assets-container:hover {
  transform: translateY(-5px);
} */

.asset-item {
  display: flex;
  align-items: center; /* Center items vertically */
  max-width: 100%; /* Set max-width to restrict width of the container */
  overflow: hidden; /* Prevent content from spilling out */
}

.asset-icon img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.asset-name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1; /* Take up remaining space */
}

.dropdown-icon {
  position: absolute;
  top: 5px;
  right: 2px;
  z-index: 10;
  width: 20px;
  border-radius: 40px;
  height: 20px;
  padding-top: 3px;
}
.dropdown-icon:hover {
 background-color: #dadada;
 cursor: pointer;
}
.dropdown-menu-width{
  width: 80px;
}
.dropdown-menu {
  position: absolute;
  top: 30px;
  right: 8px;

  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  z-index: 999999999999;
}

.dropdown-menu .btn-dropdown {
  width: 100%;
  padding: 8px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background 0.2s ease;
}

.dropdown-menu .btn-dropdown:hover {
  background-color: #f0f0f0;
}

/* Optional: Adjustments for smaller screens */
@media (max-width: 768px) {
  .asset_single_folder {
    grid-template-columns: repeat(
      auto-fit,
      minmax(150px, 1fr)
    ); /* Smaller min width on smaller screens */
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .asset_single_folder {
    grid-template-columns: repeat(
      auto-fit,
      minmax(120px, 1fr)
    ); /* Even smaller min width for mobile */
    gap: 10px;
  }
}
.asset-button {
  background-color: #5ca127;
  color: white;
  border: none;
  text-align: left;
  font-size: 10px;
  font-family: "Lato";
  padding: 0 10px;
  font-weight: 600;
  border-right: 1px solid white;
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
}
.asset-button img {
  padding-right: 5px;
}
.asset-button p {
  padding-left: 8px;
}
.asset-detail-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.asset-detail-field strong {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 700;
  align-items: left;
  color: #51595e;
}
.asset-owner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
}
.asset-detail-field input {
  border: none;
  background-color: #f8f8f8 !important;
  border-radius: 5px;
  padding: 10px;
  color: #51595e;
  font-family: "Lato";
  font-size: 15px;
  align-items: left;
  width: 70%;
}
.vendorname {
  border: none;
  background-color: #f8f8f8;
  border-radius: 5px;

  color: #51595e;
  font-family: "Lato";
  font-size: 15px;
  align-items: left;
  width: 70%;
}
.vendorname input {
  width: 100%;
}
.vendorname {
  position: relative;
}

.vendorname > .asset-list {
  
  max-height: 132px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  width: 100%;
}
.asset-list .data-list {
  justify-content: flex-start;
  margin-bottom: 0;
}
.asset-detail-field textarea {
  border: none;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 10px;
  color: #51595e;
  font-family: "Lato";
  font-size: 15px;
  align-items: left;
  width: 70%;
}
.input-no-border {
  border: none;
  outline: none;
}
.red {
  color: red;
}
.service-tickets-section {
  background-color: white;
  margin: 20px;
  padding: 0px;
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  width: 666px;
  height: 580px;
  overflow-x: auto;
}

.service-ticket-content {
  overflow-x: hidden;
  overflow-y: scroll;

}

.header-one {
  display: flex;
  /* margin: 10px 5px; */
  align-items: center;
  justify-content: flex-end;
  background-color: #e7e7e7;
  padding: 10px;
}
.header-one-btn-one {
  background-color: #599b26;
  font-size: 11px;
  font-family: "Montserrat";
  font-weight: 500;
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 5px;
  text-transform: capitalize;
  margin-right: 10px;
}
.header-one-btn-two {
  background-color: #446fa2;
  font-size: 11px;
  font-family: "Montserrat";
  font-weight: 500;
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 5px;
  text-transform: capitalize;
  margin-right: 10px;
}

.header-two {
  display: flex;
  margin: 9px 15px;
  align-items: center;
  justify-content: space-between;
}
.header-two h2 {
  font-size: 18px;
  font-family: "Montserrat";
  text-align: left;
  font-weight: 600;
  color: #51595e;
}
.asset-stpmain-top {
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  border-bottom: 1px solid #e1e1e1;
}
.service-search-filter {
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.asset-search input {
  width: 347px;
  height: 31px;
  border: none;
  background-color: #fff;
  color: #51595e;
  font-size: 12px;
  font-family: "Lato";
  font-style: italic;
  padding-left: 10px;
  border-radius: 5px;
}
.asset-search {
  background-color: white;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  margin-bottom: 4px;
}
.search-icon {
  color: #51595e;
}
.asset-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.asset-filter label {
  font-size: 15px;
  color: #51595e;
  font-weight: 700;
  text-align: left;
  padding-right: 8px;
  text-transform: capitalize;
  font-family: "Lato";
}
.asset-filter select {
  width: 116px;
  height: 31px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  color: #51595e;
  font-size: 12px;
}
.service-ticket-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #446fa2;
}
.asset-head-row {
  width: 117px;
  border: 1px solid white;
  font-size: 10px;
  font-family: "Montserrat";
  font-weight: 600;
  height: 30px;
  text-align: center;
  padding-top: 7px;
}
.parts {
  /* width: 170px; */
  border: 1px solid white;
  font-size: 10px;
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 600;
  flex: 0.25;
}
.parts-description {
  /* width: 350px; */
  border: 1px solid white;
  font-size: 10px;
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 600;
  flex: 0.5;
}
.service-ticket-label,
.service-ticket-content {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
}

.asset-head-row {
  flex-grow: 1;

  text-align: center;
  white-space: nowrap;
}
.asset-row {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding: 10px;
  padding-left: 15px !important;
  border: 1px solid white;
  font-size: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: left;
  color: #51595e;
}
.asset-head-row,
.asset-row {
  flex-grow: 1;
  flex-basis: 0;

  white-space: nowrap;
}
.no-ticket {
  padding: 10px;
  margin: auto;
  border: 1px solid white;
  font-size: 15px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
  color: #51595e;
}
.upload-file-btn {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  font-size: 12px;
  border: none;
  background-color: #446fa2;
  border-radius: 5px;
  color: white;
 
  width: fit-content;
  
 
 
  margin-right: 10px;
}
.filesfolders{
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-file-btn p {
  padding: 0 4px;
  margin: 8px 0;
}

.folders-close {
  position: absolute;
  top: 5px;
  right: 5px;
}
.modal-content {
  position: relative;
  background-color: white;
  margin: 100px auto;
  padding: 20px 20px;
  height: 620px;
  width: 50%;
  border-radius: 5px;
}
.modal-content-input {
  background-color: white;
  margin: 200px auto;
  padding: 20px;
  height: auto;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content-input input {
  font-size: 14px;
  padding: 10px;
  margin-bottom: 5px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 15px 10px; /* Optional: add padding if needed */
}
.modal-header h2{
 margin: 0;
 padding: 10px 0 0 0 
}
.top-right-btns {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between the buttons */
}

.modal-button {
  background-color: transparent;
  border: 2px solid red;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}
.modal-body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%; /* Full height of the parent container */
  overflow-y: auto; /* Enable scrolling if content exceeds the height */
}

.directory-file-container {
  position: relative;
}
.upload-image-dropdown {
  position: absolute;
  top: 40%;
  right: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 100px;
}

.upload-image-dropdown button {
  display: block;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #ccc;
  font-size: 12px;
  font-family: "Lato";
  width: 100%;
  cursor: pointer;
}
.part-content {
  width: 171px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding: 8px;
  border: 1px solid white;
  font-size: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: center;
  color: #51595e;
}
.part-description {
  width: 350px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding: 8px;
  border: 1px solid white;
  font-size: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: center;
  color: #51595e;
}

@media (max-width: 1590px) {
  .asset-search input {
    width: 300px;
  }

  .my-asset-details {
    width: 800px;
  }
  .service-tickets-section {
    width: 950px;
  }

  .asset-info-section {
    margin: 0 50px;
  }
  .asset-info-section {
    margin: 0 0px 0 10px;
}
.asset-search {

  max-width: 350px;
}
}
@media (max-width: 1400px) {
  .asset-button p {
    padding-left: 0; /* Reduce text size further if necessary */
  }
}
@media (max-width: 1300px) {
  .asset-filter select {
    width: 90px;
  }
  .asset-search input {
    width: 280px;
  }
}

/* Global styles and smaller screen adjustments */
@media only screen and (max-width: 1200px) {
  .asset-info-section {
    flex-direction: column;
    align-items: center;
    margin: 0 ;
  }

  .asset-search input {
    width: 300px;
  }

  .parts-description {
    width: 380px;
  }
  .asset-details-content {
    width: 100%;
    padding: 0;
  }

  .my-asset-details {
    width: 700px;
  }
  .service-tickets-section {
    width: 700px;
  }
  .modal-content {
    width: 60%;
  }
  .part-content {
    width: 165px;
  }
  .part-description {
    width: 369px;
  }
  .asset-search {

    max-width: 300px;
  }
}
@media (max-width: 880px) {
  .my-asset-details {
    width: 550px;
  }

  .service-tickets-section {
    margin-top: 20px;
    width: 550px;
  }
  .asset-search {
    width: 280px;
  }
  .modal-content {
    width: 80%;
  }
}
@media only screen and (max-width: 768px) {
  .asset-owner {
    width: 100%;
  }
  .asset-detail-field {
    flex-direction: column;

    align-items: flex-start;
  }
  .my-asset-details {
    width: 85%;
    height: auto;
    margin-left: 70px;
    margin-right: 0;
  }

  .service-tickets-section {
    margin-top: 20px;
    width: 85%;
    margin-bottom: 100px;
    margin-left: 70px;
    margin-right: 0;
  }
  .asset-search {
    width: 280px;
  }

  .asset-detail-field input {
    width: 100%;
  }
  .asset-detail-field textarea {
    width: 100%;
  }
  .service-ticket-table {
    overflow-x: auto;
    white-space: nowrap;
  }
  .service-search-filter {
    overflow-x: auto;
    white-space: nowrap;
  }
  .service-ticket-label,
  .service-ticket-content {
    min-width: 600px; /* Adjust the minimum width for smaller screens */
  }
  .vendorname {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .asset-search {
    width: 270px;
  }
  .asset-filter select {
    width: 90px;
  }
  .service-search-filter {
    padding: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .asset-info-section {
    margin: 0;
  }
  .asset-details-content {
    margin-top: 2px;
  }
  .asset-list .data-list > div {
    width: auto;
  }
  .asset-detail-field strong {
    font-size: 12px;
  }

  .asset-detail-field input,
  .asset-detail-field textarea {
    font-size: 12px;
    padding: 8px;
  }
  .modal-content {
    width: 95%;
  }
  .my-asset-details {
    width: 100%;
    margin: 0;

  
  }

  .service-tickets-section {
    width: 100%;
    margin: 20px 0 70px 0;

  }
  .asset-info-section {
    margin: 0 ;
}
}
@media (max-width: 500px) {
  .my-asset-details,
  .service-tickets-section {
    width: 100%;
  }

  .asset-details-left-header {
    padding: 5px 20px; /* Adjusted padding for this specific element */
  }

  .service-search-filter {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow-x: auto;
    white-space: nowrap;
  }

  .asset-search input {
    flex-shrink: 1; /* Ensures the input box resizes properly on small screens */
    min-width: 150px; /* Prevents input from shrinking too much */
  }

  .asset-filter {
    margin-left: 50px;
  }
  .header-two {
    margin: 9px 5px;
  }
  .asset-button {
    padding: 6px 8px; /* Adjust padding to fit content better */
    font-size: 8px; /* Slightly reduce text size */
  }

  .asset-button p {
    font-size: 9px;
    padding-left: 0; /* Reduce text size further if necessary */
  }
  .modal-content {
    height: 80%;
  }
  .modal-header h2{
    font-size: 15px;
  }
  .create-folder {
   
    font-size: 9px;
  }
  .upload-file-btn {
   
    font-size: 9px;
  }
}

.severity{
  display: flex;
  padding: 6px 8px !important;

align-items: center;
justify-content: flex-start;
}
.severity img{
  width: 20px;
  margin-right: 5px;
}

.modal-content{
  overflow: auto;
}
.asset_single_files {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next row */
  justify-content: space-between; /* Ensures even spacing between items */
  gap: 20px; /* Adds consistent spacing between rows and columns */
  width: 100%; 

 
}

.assets-container-files {
  flex: 0 1 calc(50% - 20px); /* Each item takes up 50% of the row, minus the gap */
  height: auto; /* Adjusts height automatically based on content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth hover effects */
}
@media(max-width:500px){
  .assets-container-files {
    width: 100%;
    flex: 0 1 calc(100% - 20px);
  }
}
@media(max-width:400px){
  .asset-button {
    padding: 6px 2px 4px 2px;
    
}
.asset-button p {
  font-size: 8px;

}
.asset-button img {
  width: 17px !important;
}
}
.service-ticket-table {
  display: flex;
  flex-direction: column;

}

.service-ticket-label,
.service-ticket-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.parts,
.part-content {
  flex: 1; /* Equal width for PART# and QUANTITY ON HAND */
}

.parts-description,
.part-description {
  flex: 2; 
  
}

.service-ticket-label > div,
.service-ticket-content > div {
  padding: 8px; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding in width */
}
.bookmark-asset{
width: 100%;
background-color: #f3f1f1;
height: auto;
padding: 5px;

}
.popupStyle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.messagestyling {
  background-color: rgba(255, 255, 255,0.7);
  color: rgb(0, 0, 0);
  font-weight: bold;
  padding: 20px;
  padding-top: 180px;
  font-size: 24px;
width: 50%;
height: 50%;
  border-radius: 5px;
  text-align: center;
}
@media (max-width:600px) {
  .messagestyling {
    width: 100%;
    font-size: 18px;
  }
}