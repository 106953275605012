.share-asset-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.share-asset-container {
  background: white;
  padding: 0 20px 20px 20px;
  width: 503px;
  height: 236px;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.share-asset-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  font-family: "Lato";
  color: #446fa2;
  border-bottom: 1px solid #d2d2d2;
  padding: 10px 0;
}

.close-asset-sharing-popup {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0px;
  position: relative;
  left: 35%;
  bottom: 5px;
}

.share-asset-dropdown {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.share-asset-dropdown label {
  text-align: left;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 700;
  font-family: "Montserrat";
  color: #51595e;
}

.share-asset-dropdown input {
  padding: 8px;
  margin-bottom: 10px;
  width: 450px;
  height: 44px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  font-family: "Lato";
}

.share-asset-dropdown input::placeholder {
  font: italic normal normal 12px/14px Lato;
  letter-spacing: 0px;
  color: #51595e;
}

.share-btn {
  background: #446fa2;
  border-radius: 5px;
  text-align: center;
  border: none;
  padding: 10px;
  font: 14px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Montserrat";
  width: 38%;
  margin: auto;
  margin-top: 15px;
}
.sharing-list > .asset-list {
    max-height: 250px;
    overflow-y: auto;
    background-color: white;
    position: absolute;
    width: 450px;
  }
@media (max-width: 500px) {
  .share-asset-container {
    width: 100%;
  }
 .sharing-list > .asset-list {
width: auto;
  }
  .share-asset-dropdown input {
    width: 100%;
  }
  .close-asset-sharing-popup {
    
    left: 27%;

  }
}
