.create-appointment-container {
  width: 100%;
  background-color: white;
  height: fit-content; /*static right now*/
  border-radius: 5px;
}
.organization-list .data-list {
  display: flex;
  justify-content: space-between;
  padding: 5px 12.5px;
  border: solid 1px #989a9b;
  align-items: center;
  border-radius: 5px;
}
.organization-list .data-list img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
  border: solid 1px #989a9b;
}
.organization-list .data-list > div {
  display: flex;
  align-items: center;
}
.organization-list .data-list label > div {
  display: flex;
  align-items: center;
}
.organization-list .data-list label > div > div {
  display: flex;
  flex-direction: column;
}
.organization-list .data-list label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.organization-list .data-list label input {
  width: fit-content;
  margin-right: 25px;
}
.organization-list > div {
  margin-bottom: -1px;
}
.organization-list .data-list span {
  margin-left: 20px;
  font-weight: bold;
}
.organization-list .data-list button {
  height: 35px;
  background: #446fa2;
}
.appointment-form-organization .data,
.appointment-form-user .data {
  position: relative;
}
.appointment-form-organization .organization-list,
.appointment-form-user .organization-list {
  position: absolute;
  width: 100%;
  background: #f5f5fb;
  cursor: pointer;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 1;
}
.appointment-form-organization .organization-list .orgListAlert,
.appointment-form-user .organization-list .orgListAlert {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appointment-form-upload .organization-list {
  position: absolute;
  width: 100%;
  background: #f5f5fb;
  cursor: pointer;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 1;
  top: 50px;
}
.appointment-form-upload .organization-list label.organization-folder > div i {
  font-size: 10px;
  color: #7f8c8d;
}
.appointment-form-upload .organization-list label.organization-folder {
  display: flex;
  padding: 5px 0;
  align-items: center;
}
.appointment-form-upload .organization-list label.organization-folder > div {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.appointment-form-upload
  .organization-list
  label.organization-folder
  > div
  img {
  width: 50px;
  margin-right: 12.5px;
}
.appointment-form-upload .organization-list label.organization-folder input {
  width: fit-content;
  margin-right: 15px;
  margin-top: unset;
  margin-left: 15px;
}
.appointment-form-upload .orgListAlert {
  text-align: center;
  padding: 25px 0;
}
.multiple-line-text {
  display: flex;
  flex-direction: column;
}
.appointment-form-organization .data textarea {
  padding: 10px;
  color: #51595e;
  font-family: var(--lato);
  font-size: 13px;
  font-weight: bold;
  resize: none;
  width: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  /* text-transform: capitalize; */
}

.appointment-form-upload.stcreate-fields > div {
  width: unset;
}
.appointment-form-upload.stcreate-fields div.data {
  width: 100%;
}
.appointment-form-upload.stcreate-fields .organization-list {
  max-height: 100px;
}
.appointment-form-upload.stcreate-fields .organization-list .data-list img {
  width: 35px;
  height: 25px;
}
.appointment-form-upload.stcreate-fields div.data input {
  height: 48px;
}
.rc-time-picker-input-custom-container {
  display: flex;
  align-items: center;
}
.rc-time-picker-input {
  height: 48px !important;
  width: 102px !important;
  color: #51595e !important;
  font-weight: bold !important;
  font-family: var(--lato) !important;
}
.rc-time-picker-panel-inner {
  border: unset !important;
}
.rc-time-picker-input-first {
  margin-right: -5px !important;
}
.rc-time-picker-input-last {
  margin-left: 10px !important;
  margin-right: 0 !important;
}

.rc-time-picker-icon,
.rc-time-picker-clear {
  display: none !important;
}
/* .multiple-line-text span {
  text-overflow: ellipsis;
  white-space: nowrap;
} */

@media screen and (max-width: 1347px) {
  .rc-time-picker-input-custom-container {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .rc-time-picker-input-custom-container {
    margin-top: 0;
  }
}
@media screen and (max-width: 609px) {
  .rc-time-picker-input-custom-container {
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .rc-time-picker-input-custom-container {
    margin-top: 0;
  }
}
@media screen and (max-width: 493px) {
  .rc-time-picker-input-custom-container {
    margin-top: 10px;
  }
}
.error-message {
  color: #c51414; /* Red text */
  background-color: #fcfbfb; /* Light red background */
  border: 1px solid #be1414; /* Red border */
  border-radius: 4px; /* Rounded corners */
  padding: 2px; /* Padding for visibility */
  margin-top: 2px; /* Space between the input and message */
  font-size:12px; /* Smaller font size */
  display: block; /* Ensures it takes the full width */
}
