.create-assets-parent {
  position: relative;
  height: 100%;
}
.create-assets-navigation {
  position: fixed;
  bottom: 0;
  z-index: 4;
  width: 100%;
  /* height: 60px; */
}
.create-assets-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.create-assets-main {
  background-color: #fff;
  width: 666px;
  height: 560px;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  /* padding: 5px; */
  box-shadow: 0px 5px 40px #00000005;
  border-radius: 5px;
  overflow-y: auto;
  margin-bottom: 200px
}
.create-asset-heading {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 600;
  color: #51595e;
  border-bottom: 1px solid #e1e1e1;
  padding: 5px;
}
.create-asset-heading h2 {
  margin: 15px;
}
.submitbutton button {
  color: white;
  background-color: #5ca127;
  border-radius: 5px;
  opacity: 1;
  width: 150px;
  height: 35px;
  border: none;
  text-transform: Uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  margin: 18px;
}

.create-asset-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 30px;
}
.create-asset-fields input {
  border: 1px solid #e1e1e1;
  padding: 15px;
  border-radius: 5px;
  opacity: 1;
  width: 409px;
  height: 44px;
  color: #51595E;
  font-family: "Lato";
  font-size: 15px;
  font-weight: normal;
}
.create-asset-fields textarea{
  border: 1px solid #e1e1e1;
  padding: 15px;
  border-radius: 5px;
  opacity: 1;
  width: 409px;
  resize: none;
  color: #51595E;
  font-family: "Lato";
}
.create-asset-fields textarea::-webkit-input-placeholder {
  text-align: left;
  color: #919191;
  opacity: 1;
  font-style: italic;
  font-family: "Lato";
  font-size: 14px;
}
.create-asset-fields input::-webkit-input-placeholder {
  text-align: left;
  color: #919191;
  opacity: 1;
  font-style: italic;
  font-family: "Lato";
  font-size: 14px;
}
.create-asset-fields span{
  color: red;
}
.create-asset-fields label{
  font-size: 14px;
  font-family: "Montserrat";
  color: #51595E;
  text-align: left;
  font-weight: 700;
}
.create-asset-fields .custom-dropdown {
  border: 1px solid #e1e1e1;
  padding: 15px;
  border-radius: 5px;
  opacity: 1;
  width: 409px;
  resize: none;

  color: #51595E;
}

.custom-dropdown option {
  /* font-size: 14px; */
  text-align: left;
  font-family: "Lato";
  color: #51595E;
  font-size: 15px;
}

.custom-dropdown option[value=""] {
  text-align: left;
  color: #919191;
  opacity: 1;
  font-style: italic;
  font-family: "Lato";
  font-size: 14px;
}
.create-asset-fields .organization-list {
  position: absolute;         
  width: 100%;               
  max-height: 120px;
  overflow-y: auto;         
  cursor: pointer;           
  z-index: 1;                
  background-color: #fff;    
  border: solid 1px #989a9b; 
  border-radius: 5px;       
}
.create-asset-fields .organization-list .data-list {
  display: flex;                       
  justify-content: space-between;      
  padding: 4px 14px;             
  align-items: center;                 
  transition: background-color 0.3s;   
}

.create-asset-fields .organization-list .data-list:hover {
  background-color: #f1f1f1; 
}
@media (max-width: 860px) {
  .create-assets-main {
    width: 70%; 
    height: auto; 
    margin-top: 20px;
  
  }

  .create-asset-fields {
    flex-direction: column; /* Stack fields vertically */
    align-items: stretch; /* Allow fields to take full width */
    margin: 15px 18px; /* Adjust margins */
  }

  .create-asset-fields input,
  .create-asset-fields textarea,
  .create-asset-fields .custom-dropdown{
    width: 100%; /* Make fields full width on smaller screens */
    margin: 5px 0; /* Add spacing between fields */
  }
  
  
}
@media (max-width:600px){
  .create-assets-main {
    width: 90%; 
    height: auto; 
    margin-top: 20px; 
  }
  .create-assets-form{
    margin-bottom:110px;
    
  }
}
.location-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 10;
}

.location-dropdown ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.location-dropdown .dropdown-option {
  padding: 8px;
  cursor: pointer;
  text-align: left;
}

.location-dropdown .dropdown-option:hover {
  background-color: #f0f0f0;
}
@media screen and (max-width: 600px) {
  .folder-list .data-list > div {
      width: 69%;
  }
}