.create-parts{
    height: auto !important ;
}
@media (max-width: 860px) {
    .create-parts {
      
        height: auto;
    
    }
}
.vendor-wrapper {
    position: relative;
  }
  

  .vendor-organization-list {
    position: absolute;
    top: 100%; /* Aligns directly below the input field */
    left: 0;
    width: 100%; /* Matches the width of the input */
    border: 1px solid #ddd;
    background-color: #fff;
    z-index: 10;
    max-height: 200px; /* Add a max-height with scroll if needed */
    overflow-y: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  .vendor-organization-list  .data-list {
    display: flex;
    justify-content: space-between;
    padding: 5px 12.5px;
    border: solid 1px #989a9b;
    align-items: center;
    border-radius: 5px;
  }
  .vendor-organization-list .data-list img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
    border: solid 1px #989a9b;
  }
  .vendor-organization-list  .data-list > div {
    display: flex;
    align-items: center;
  }
  .vendor-organization-list  .data-list label > div {
    display: flex;
    align-items: center;
  }
  .vendor-organization-list  .data-list label > div > div {
    display: flex;
    flex-direction: column;
  }
  .vendor-organization-list  .data-list label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .vendor-organization-list  .data-list label input {
    width: fit-content;
    margin-right: 25px;
  }
  .vendor-organization-list  > div {
    margin-bottom: -1px;
  }
  .vendor-organization-list  .data-list span {
    margin-left: 20px;
    font-weight: bold;
  }
  .vendor-organization-list  .data-list button {
    height: 35px;
    background: #446fa2;
  }

  /* General styles */

/* Dropdown styles */
.dropdown-list {
  max-height: 150px;
  overflow-y: auto;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 4px;
  cursor: pointer;
  border-radius: 3px;
  transition: background 0.2s;
}

.dropdown-item:hover {
  background: #f0f8ff;
}

.dropdown-text {
  color: #446fa2;
  font-size: 14px;
}

/* Input Wrapper */
.input-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  position: relative;
}
.input-box {
  grid-column: span 2;
  outline: none;
  min-width: 80px;
}

/* Location Chip with Quantity */
.location-chip-with-quantity {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  border: 1px solid #446fa2;
  border-radius: 5px;
  padding: 5px;
  gap: 2px;
  justify-content: space-between;
}
/* Chip for Selected Locations */
.location-chip {
  display: flex;
  align-items: center;
 
}
.chip-text {
  font-size: 14px;
}
.chip-remove-btn {
  background: transparent;
  border: none;
  font-size: 10px;
  color: #51595E;
  cursor: pointer;
  padding-left: 2px;
}
/* Quantity Controls */
.quantity-controls {
  display: flex;
  align-items: center;
  gap: 1px;
}
.quantity-btn {
  width: 20px;
  height: 20px;
  border: none;
  background: #446fa2;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.3s;
}
.quantity-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}
.quantity-btn:hover:enabled {
  background: #335a82;
}
.quantity-value {
  min-width: 20px;
  font-size: 12px;
  text-align: center;
}

@media (max-width: 860px) {
  .input-wrapper{
      width: 100%;
      margin: 5px 0;
      display: block;
  }
.input-box{
  margin: 0 !important;
}

}
