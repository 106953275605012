.trash-wrapper {
  width: 70%;
  margin: 0 auto;
  background: white;
  border-radius: 5px;
  height: fit-content;
  margin-top: 25px;
}
.trash-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.trash-lower .footer-para {
  margin-left: 25px;
}
.trash-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trash-head {
  color: #51595e;
  font-weight: bold;
  font-size: 18px;
  font-family: var(--montserrat);
}
.trash-upper {
  display: grid;
  align-items: center;
  grid-template-columns: 55px 1fr;
  padding: 20px 30px;
  border-bottom: solid 2px var(--border-color);
}
.trash-upper label {
  color: #51595e;
  font-weight: bold;
  font-size: 18px;
  font-family: var(--montserrat);
}
.trash-logo {
  border-radius: 50%;
  max-width: 75px;
  height: 67.5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--border-color);
  margin-right: 15px;
}
.trash-logo img {
  width: 100%;
  object-fit: contain;
}
.trash-data h3 {
  margin-bottom: unset;
  margin-top: 5px;
  font-family: var(--lato);
  font-weight: bold;
  font-size: 16px;
  color: #51595e;
}
.trash-data p {
  font-family: var(--lato);
  margin-top: unset;
  color: #647589;
  font-size: var(--secondary-text);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 96%;
  overflow: hidden;
}
.trash-card-wrapper {
  width: 47.5%;
  border-radius: 5px;
  margin-bottom: 7.5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #70707021;
  height: fit-content;
}
.trash-lower {
  height: 750px;
  overflow-y: scroll;
  /* display: flex;
  flex-wrap: wrap;
  padding: 15px 30px;
  justify-content: space-between;
  align-content: flex-start; */
}
.trash-accept {
  margin-left: 10px;
}
.trash-options > div {
  cursor: pointer;
}
.trash-card-info {
  justify-content: flex-start;
  overflow: hidden;
  padding: 10px;
}
.trash-options {
  flex: 0 0 20%;
  justify-content: center;
}
.trash-card-info .trash-logo {
  flex: 0 0 20%;
}
.trash-card-info .trash-content {
  flex: 0 0 80%;
  width: 100%;
  overflow: hidden;
}
.trash-data {
  width: 100%;
  overflow: hidden;
}
.render-trash-card-tabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.render-trash-card-tabs-container button {
  font-size: 12px;
  width: 178px;
  height: 35px;
  box-shadow: none;
  border-radius: 5px;
}
.render-trash-card-tabs-content {
  padding: 20px 30px;
}
.render-trash-card-archived-users,
.render-trash-card-tabs-content {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
}
.render-trash-card-options > div.render-trash-card-options-actions {
  border: none;
  width: max-content;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.render-trash-card-options > div.render-trash-card-options-actions > button {
  border: none;
  width: max-content;
  margin-left: 5px;
  display: inline-block;
  color: #fff;
  font-size: 11px;
  padding: 5px 30px;
  border-radius: 5px 5px 1px 0;
  background-color: rgb(92, 161, 39);
  transition: opacity 250ms ease;
}
.render-trash-card-options > button:hover {
  opacity: 0.85;
}
.render-trash-card-tabs-content p {
  font-size: 12px;
}
/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .trash-wrapper {
    width: 80%;
  }
  .trash-folder-single > div p {
    font-size: 16px;
  }
  .trash-folder-single > div {
    padding: 0 5px;
  }
  .trash-main {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
  }
  .trash-wrapper {
    width: 87.5%;
    margin: unset;
    margin-top: 25px;
  }
  .trash-folder-single,
  .trash-file-container {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
}

/* tablet */
@media screen and (max-width: 1024px) {
}
/* ipad mini  */
/* @media screen and (max-width: 900px) {} */
/* ipad air */
@media screen and (max-width: 891px) {
  .render-trash-card-archived-users,
  .render-trash-card-tabs-content {
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }
}

/* mobile  */
@media screen and (max-width: 600px) {
  .trash-folder-single,
  .trash-file-container {
    width: 100%;
  }
  .trash-container {
    height: 100%;
  }
  .trash-header {
    height: 65px;
  }
  .trash-navigation {
    position: fixed;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 60px;
  }
  .trash-main {

     height: 100%;
     padding-bottom: 70px;
    margin-right: 0;
  }
  .trash-wrapper {
    width: 100%;
    margin: unset;
    height: 100%;
    overflow-y: scroll;
  }
  .trash-lower {
    height: unset;
    overflow-y: unset;
  }
  .trash-folder-single > div img {
    width: 60px;
  }
  .trash-upper {
    grid-template-columns: 1fr;
    row-gap: 16px;
    text-align: center;
  }
  .render-trash-card-archived-users,
  .render-trash-card-tabs-content {
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }
}
@media screen and (max-width: 760px) {
  .trash-upper {
    grid-template-columns: 1fr;
    row-gap: 16px;
    text-align: center;
  }
  .render-trash-card-tabs-container button {
    width: auto;
    height: auto;
    font-size: 10px;
  }
}
/* @media screen and (max-width: 376px) {
  .render-trash-card-tabs-container button {
    width: auto;
    height: auto;
    font-size: 10px;
  }
} */
