.stdashboard-heading p {
  color: #707070;
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
  font-weight: bolder;
}
.stdashboard-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding-bottom: 30px;
}
.stdashboard-wrapper-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: max-content;
}
.stdashboard-parent {
  position: relative;
  height: 100%;
  font-family: var(--montserrat);
}
.stdashboard-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.stdashboard-main {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.stdashboard-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: 250px;
  border: 1px solid #4671a4;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  justify-content: center;
}
div.disable {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.stdashboard-card:hover,
.stdashboard-card-last-child:hover {
  background-color: #efefef;
  cursor: pointer;
}
.stdashboard-card-last-child {
  border: 1px solid #4671a4;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  width: 350px;
  display: flex;
  align-items: center;
  height: 110px;
  border-radius: 5px;
  justify-content: space-evenly;
}
.stdashboard-card.disable:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.internal-service-ticket-card {
  margin-top: 20px;
}
.stdashboard-card-last-child span {
  width: 175px;
}
.stdashboard-card > img {
  width: 176px;
  height: 176px;
}
.stdashboard-card img.hover-image {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 3%;
  right: 3%;
}
.stdashboard-card-last-child > img {
  width: 78px;
  height: 78px;
}
.stdashboard-wrapper span {
  color: #446fa2;
  font-size: 18px;
  font-weight: bold;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .stdashboard-main {
    width: 97%;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1300px) {
  .stdashboard-wrapper {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: unset;
  }
}

@media screen and (max-width: 1024px) {
  .stdashboard-wrapper span {
    text-align: center;
  }
  .stdashboard-main {
    justify-content: flex-end;
    width: 100%;
  }
  .stdashboard-card,
  .stdashboard-card-last-child {
    width: 250px;
    height: 200px;
    flex-direction: column;
  }
  .internal-service-ticket-card {
    width: 350px !important;
    height: 120px !important;
    flex-direction: row !important;
  }
  .stdashboard-card-last-child span {
    width: unset;
  }
  .stdashboard-card > img {
    width: 130px;
    height: 130px;
  }
  .stdashboard-card-last-child > img {
    width: 100px;
    height: 100px;
  }
  .stdashboard-card img.hover-image {
    width: 40px;
    height: 40px;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */

@media screen and (max-width: 768px) {
  .stdashboard-wrapper-content {
    grid-template-columns: repeat(1, 1fr);
    width: 65%;
  }
  .internal-service-ticket-card {
    height: 100% !important;
    flex-direction: column !important;
    width: 65% !important;
  }
  .stdashboard-card {
    width: 100%;
    height: 100%;
    padding: 3px 0 20px 0;
  }
  .stdashboard-card-last-child {
    width: 100%;
    height: 100%;
    padding: 20px 0;
  }
  .stdashboard-card-last-child span {
    margin-top: 8px;
  }
  .stdashboard-card > img {
    width: 130px;
    height: 130px;
  }
  .stdashboard-heading p {
    font-size: 25px;
    margin-top: 25px;
  }
}
@media screen and (max-width: 600px) {
  .stdashboard-header {
    height: 65px;
  }
  .stdashboard-main {
    width: 100%;
    justify-content: center;
    margin-top: 0;

    height: 100%;
    padding-bottom: 70px;
    overflow-y: scroll;
  }
  .stdashboard-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .stdashboard-wrapper-content {
    width: 90%;
    gap: 15px;
  }
  .internal-service-ticket-card {
    width: 90% !important;
    margin-top: 15px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
