.stpmain-parent {
  position: relative;
  height: 100%;
}
.stpmain-parent * {
  font-family: var(--montserrat);
}
.stpmain-header {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 75px;
}
.stpmain-main {
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.stpmain-ticket-search {
  width: 750px;
  height: 50px;
  background: #ffffffb3 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.stpmain-ticket-search input {
  outline: none;
  border: none;
  background: transparent;
  letter-spacing: 0px;
  color: #446fa2;
  font-size: 15px;
  font-style: italic;
  width: 100%;
}
.stpmain-ticket-search svg {
  color: #446fa2;
}
.stpmain-ticket-search input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #446fa2;
}
.stpmain-ticket-search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #446fa2;
}
.stpmain-ticket-search input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #446fa2;
}
/* .stpmain-responsive-btn {
  display: flex;
  width: 250px;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 15px;
}
.stpmain-responsive-btn button {
  cursor: pointer;
} */

.stpmain-options-btn {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.stpmain-options-btn .create-ticket {
  margin-right: 20px;
  background: #5ca127;
  border-radius: 5px;
  color: white;
  border: unset;
  padding: 10px 50px;
  cursor: pointer;
  border: solid 1px #5ca127;
}
.stpmain-options-btn .create-ticket:hover {
  background: white;
  color: #5ca127;
}
.stpmain-options-btn button:last-child {
  margin-right: unset;
}
.stpmain-outline-btn {
  border: 1px solid #446fa2;
  border-radius: 5px;
  background: white;
  color: #446fa2;
  font-weight: bold;
  padding: 10px 25px;
  margin-right: 20px;
}
.external-rights {
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  background: #c6c6c6;
  color: #fff;
  font-weight: bold;
  padding: 10px 25px;
  margin-right: 20px;
}
.stpmain-outline-btn:hover {
  background: #446fa2;
  color: white;
}
.stpmain-outline-btn-active {
  border: 1px solid #446fa2;
  border-radius: 5px;
  background: #446fa2;
  color: white;
  font-weight: bold;
  padding: 10px 25px;
  margin-right: 20px;
}
.stpmain-outline-btn-active:hover {
  background: #446fa2;
  color: white;
}
.stpmain-wrapper {
  width: 90%;
  margin: 0 10px 0 100px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  
}
.stpmain-all-active,
.stpmain-my-active {
  border-radius: 5px;
  background-color: white;
}
.stpmain-all-active {
  width: 80%;
}
.stpmain-my-active {
  width: 45%;
  margin-left: 30px;
}
.stpmain-top p {
  color: #51595e;
  font-size: 18px;
  font-weight: bold;
}
.stpmain-top {
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  border-bottom: 1px solid #e1e1e1;
}
.stpmain-top > div {
  display: flex;
  align-items: center;
  /* margin-right: 8px; */
}
.stpmain-top select {
  margin-left: 5px;
  width: 85px;
  height: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  color: #51595e;
  font-size: 14px;
  font-family: var(--lato);
  outline: none;
}

/* head row */
.stpmain-row-wrapper {
  display: flex;
  background: #446fa2;
  color: white;
  margin: 0 22px;
}
.stpmain-row-wrapper .stpmain-head-row:first-child {
  width: 10%;
}
.stpmain-row-wrapper .stpmain-head-row {
  width: 22.5%;
  text-align: center;
  font-size: 14px;
  border-right: solid 0.5px #e1e1e1;
  border-left: solid 0.5px #e1e1e1;
  padding: 2.5px 0;
}
/* content row */
.stpmain-bottom {
  overflow-y: scroll;
  height: 500px;
  cursor: pointer;
}
.stpmain-content-wrapper {
  display: flex;
  margin: 0 22px;
}
.stpmain-content-wrapper .stpmain-content-row select {
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  color: #51595e;
  font-family: var(--lato);
}
.stpmain-content-wrapper .stpmain-content-row:first-child {
  width: 10%;
  color: #446fa2;
  font-weight: bold;
}
.stpmain-content-wrapper .stpmain-content-row {
  width: 22.5%;
  font-size: 14px;
  border-bottom: solid 1px #e1e1e1;
  padding: 10px 5px;
  color: #51595e;
  font-family: var(--lato);
  display: flex;
  text-transform: capitalize;
}
.stpmain-content-wrapper .stpmain-content-row img {
  width: 20px;
  height: 18px;
  margin-right: 5px;
}
.stpmain-msg {
  text-align: center;
  margin-top: 15px;
}

/* right side */
.stpmain-right-side .stpmain-head-row:first-child {
  width: 16%;
}
.stpmain-right-side .stpmain-head-row {
  width: 28%;
}
.stpmain-right-side .stpmain-content-row:first-child {
  width: 16%;
}
.stpmain-right-side .stpmain-content-row {
  width: 28%;
}
.stpmain-content-row.stpmain-date-row svg {
  font-size: 18px;
  position: absolute;
  right: 20%;
}

/* responsive  */

/* desktop */
@media screen and (min-width: 1701px) {
}

/* laptop */
@media screen and (max-width: 1600px) {
  .stpmain-main {
    width: 97%;
    justify-content: flex-end;
  }
  .stpmain-row-wrapper,
  .stpmain-content-wrapper {
    margin: 0 5px;
  }
}

@media screen and (max-width: 1400px) {
  .stpmain-row-wrapper .stpmain-head-row,
  .stpmain-content-wrapper .stpmain-content-row {
    font-size: 12px;
  }
  .stpmain-content-wrapper .stpmain-content-row select {
    font-size: 12px;
  }
  .stpmain-wrapper {
    width: 90%;
  }
 
.sttop-heading{
  margin-right: 20px;
}
}
@media screen and (max-width: 1250px) {
  .stpmain-wrapper {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
  }
  
  .stpmain-all-active {
    width: 100%;
    margin-bottom: 15px;
  }
  .stpmain-my-active {
    width: 100%;
    margin: unset;
  }
  .stpmain-bottom {
    height: 250px;
  }
}


@media screen and (max-width: 1024px) {
  .stpmain-main {
    justify-content: flex-end;
    width: 100%;
  }
  .stpmain-ticket-search {
    width: 650px;
  }
  .stpmain-content-row.stpmain-date-row svg {
    top: 8px;
  }
 
}

/* ipad air  */
@media screen and (max-width: 900px) {
  .stpmain-my-active,
  .stpmain-all-active {
    width: 100%;
    overflow-x: scroll;
  }
  

  .stpmain-bottom,
  .stpmain-row-wrapper,
  .stpmain-top {
    width: 700px;
  }

  .stpmain-row-wrapper .stpmain-head-row {
    font-size: 13px;
  }
  .stpmain-top p {
    font-size: 18px;
  }
  .stpmain-my-active .stpmain-top p {
    font-size: 16px;
  }
}
/* ipad air  */

@media screen and (max-width: 768px) {
  .stpmain-ticket-search {
    width: 450px;
  }
  .stpmain-top p {

    margin-right: 0;
}
  .stpmain-options-btn button {
    font-size: 11px;
  }
  .stpmain-options-btn {
    width: 600px;
    margin: 0 auto;
    margin-top: 15px;
  }
  .stpmain-row-wrapper .stpmain-head-row {
    font-size: 13px;
  }
}
@media screen and (max-width: 700px) {
  .stpmain-options-btn {
    width: 450px;
  }
  .stpmain-options-btn button {
    font-size: 12px;
    padding: 7px 12px;
  }
}

@media screen and (max-width: 600px) {
  .stpmain-header {
    height: 65px;
  }
  .stpmain-main {
    width: 100%;
    justify-content: center;
    height: 100%;
    padding-bottom: 30px;
    overflow-y: scroll;
  }
  .stpmain-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  .stpmain-wrapper {
    flex-direction: column;
    width: 95%;
  }
  .stpmain-my-active {
    margin-bottom: 50px;
  }
  .stpmain-top p {
    font-size: 14px;
  }
}
@media screen and (max-width: 505px) {
  .stpmain-top p {
    font-size: 12px;
  }
  .stpmain-top {
    padding: 0 5px;
  }
  .stpmain-ticket-search {
    width: 300px;
  }
  .stpmain-ticket-search input {
    font-size: 10px;
  }
  .stpmain-options-btn {
    width: 300px;
  }
  .stpmain-options-btn button {
    font-size: 10px;
  }
}
@media screen and (max-width: 376px) {
}
@media screen and (max-width: 330px) {
}
