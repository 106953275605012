.chat-parent {
  position: relative;
  height: 100%;
}
.chat-header {
  position: sticky;
  top: 0;
  z-index: 10;
  height: 75px;
}
.chat-main {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 35px;
}
.chat-left {
  width: 35%;
  margin-right: 1%;
}
.chat-right {
  width: 55%;
  margin-left: 1%;
}
.chat-main button {
  font-family: var(--montserrat);
}
.no-chat {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
  font-family: var(--montserrat);
}
.no-chat img {
  width: 50px;
  margin-right: 5px;
}
.chatorganization-lower .result-button {
  flex: 0 0 22.5%;
}

/* responsive  */
/* desktop */
@media screen and (min-width: 1701px) {
}

@media screen and (max-width: 1600px) {
  .video-call video.peer-video {
    height: 400px;
  }
  .chat-main {
    width: 97%;
    justify-content: flex-end;
  }
  div.chatconnection.lower {
    height: 325px;
  }
  div.chat-screen-lower {
    height: 640px;
  }
  div.chat-screen-chatsection {
    height: 550px;
  }
  div.chatorganization-search .result-button button {
    font-size: 6px;
    font-weight: normal;
  }
}
@media screen and (max-width: 1300px) {
  .video-call video.peer-video {
    height: 300px;
  }
}
@media screen and (max-width: 1024px) {
  .chat-left {
    width: 87.5%;
    margin-right: unset;
    margin-bottom: 15px;
  }
  .chat-right {
    width: 87.5%;
    margin-left: unset;
    height: 500px;
  }
  .chat-main {
    justify-content: flex-end;
    width: 100%;
    flex-wrap: wrap;
  }
  .video-call video.peer-video {
    height: 250px;
  }
  .chatorganization-container {
    display: none;
  }
  div.chatconnection.lower {
    height: 240px;
  }
  div.chat-screen-lower {
    height: 450px;
  }
  div.chat-screen-chatsection {
    height: 350px;
  }
}

/* ipad air  */
@media screen and (max-width: 900px) {
}
/* ipad air  */

@media screen and (max-width: 768px) {
  .video-call video.peer-video {
    height: 200px;
  }
}
@media screen and (max-width: 600px) {
  .chat-header {
    height: 65px;
  }
  .chat-main {
    width: 100%;
    justify-content: center;
    margin-top: 0;
    height: 100%;
    padding-bottom: 80px;
    overflow-y: scroll;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .chat-right {
    width: 100%;
  }
  .card-container {
    width: 95%;
    margin: 0 auto;
  }
  .chat-navigation {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: #a3c1f5;
    z-index: 1;
    height: 60px;
  }
  div.peers-container > div {
    margin-bottom: 15px;
    width: 50%;
  }
  .video-call video.peer-video {
    height: 165px;
  }
  .chat-left {
    width: 100%;
    margin-bottom: 20px;
    height: fit-content;
  }
  div.chatconnection.lower {
    height: 250px;
  }
  div.chat-screen-logo img {
    width: 50px;
    height: 50px;
  }
  .chat-screen-profile * {
    font-size: 16px;
  }
  .chat-screen-profile h3 {
    font-size: 16px;
    font-weight: normal;
  }
  .chat-screen-options{
    gap: 8px;
    justify-content: end;
  }
  .chat-screen-options img {
    margin-left: 0 !important;
    width: 25px;
    height: 20px;
  }
  .chat-screen-upper {
    padding: 7.5px !important;
  }
  .chatconnection-upper h3 {
    margin: 7.5px 0;
    text-align: center;
  }
  div.chat-screen-lower {
    height: 450px;
  }
  div.chat-screen-chatsection {
    height: calc(100% - 55px) !important;
  }
  div.chat-screen-body {
    height: 100%;
  }
  .chat-screen-head > div:first-child {
    flex: 0 0 50%;
    overflow: hidden;
  }
  .chat-screen-profile {
    overflow: hidden;
  }
  button.chat-screen-send {
    padding: 7.5px;
  }
  .chat-screen-input {
    padding: 5px !important;
    width: 80% !important;
  }
  .chat-screen-inputsection {
    height: 45px !important;
  }
  .chat-screen-input img {
    width: 20px;
    height: 20px;
  }
  div.chatusercard-container {
    padding: 7.5px;
  }
  div.chatusercard-container .chat-screen-logo img {
    width: 35px;
    height: 35px;
  }
  div.chat-screen-time {
    font-size: 12px;
  }
  div.chatusercard-container h5 {
    font-size: 14px;
  }
  div.chat-screen-profile p,
  div.chat-screen-profile b {
    font-size: 11px;
  }
}
@media screen and (max-width: 376px) {
  div.peers-container > div {
    margin-bottom: 15px;
    width: 100%;
  }
  .video-call video.peer-video {
    height: 175px;
  }
}
@media screen and (max-width: 330px) {
  div.chat-screen-options svg {
    font-size: 24px;
  }
  div.chat-screen-options img {
    width: 22.5px;
    height: 17.5px;
  }
}
