/* Trash Asset Card */
.trash-asset-card {
    position: relative; /* Ensures that child elements are positioned relative to the card */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow around the card */
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}

/* Card Content */
.trash-asset-card-content {
    display: flex;
/*  
    padding: 20px; */
    justify-content: flex-start;
    align-items:center;
    width: 100%;
}

/* Icon Container */
.trash-asset-icon-container {
    padding: 5px;
    padding-left:  12px; 
  
    display: flex;
    justify-content: center;
}
.trash-asset-icon-container >img {
    padding-top:  5px; 
margin-top: 5px;
}
/* Asset Icon Styling */
.trash-asset-icon {
    width: 60px;
    height: 60px;
    object-fit: cover;
border-radius: 5px;
}

/* Asset Info (Text) */
.trash-asset-info {
    text-align: left; /* Center the text for better alignment */
  display: flex;
 flex-direction: column;
    margin-left: 20px;
}
.trash-asset-info span {
    font-family: var(--lato);
    color: #51595e;
    font-weight: bold;
    font-size: var(--primary-heading);
    margin-left: 10px;
 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.trash-asset-info i {
    font-family: var(--lato);
    
    font-size: 13px;
    color: #7f8c8d;
    
    margin-left: 10px;
 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.trash-asset-info p {
    font-size: 13px;
  padding: 0
}

/* Admin Options Button (Icon) */
.trash-asset-options {
    position: absolute; /* Position it relative to the card */
    top: 10px; /* Adjust top positioning */
    right: 10px; /* Position the button to the right */
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 10000000000000000000 !important; /* Ensure it's on top of other content */
}

/* Admin Options Icon Image */
.trash-asset-options img {
    width: 20px;
    height: 20px;
}

/* Dropdown Menu */


/* Dropdown Items */
.asset-trash ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Dropdown Item Styling */
.asset-trash li {
    padding: 10px;
    cursor: pointer;
}

.asset-trash {
    position: absolute;
    top: 30px;
    right: 10px;
    z-index: 10;
}

/* Show the dropdown when 'open' class is added */
.trash-asset-options.open + .asset-trash  {
    display: block;
}
.trash-asset-card {
    position: relative; /* This is required for absolute positioning of dropdown */
  }
  .dropdown-menu {
  
    z-index: 9999; 
  }
    