.profile-container {
  width: 100%;
  background-color: white;
  border-radius: 5px;
}
.profile-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-head {
  color: #51595e;
  font-size: 18px;
}
.profile-header-section {
  padding: 20px 30px;
  border-bottom: solid 2px var(--border-color);
}
.profile-details {
  border-bottom: solid 2px var(--border-color);
  padding: 20px 30px;
  padding-top: 0;
  display: flex;
}
.profile-details:first-child {
  padding-top: 20px;
}
.profile-logo {
  display: flex;
  flex-direction: column;
}
.profile-logo img {
  object-fit: contain;
  border-radius: 50%;
  width: 130px;
  height: 130px;
}
.profile-text h3 {
  margin-top: unset;
  margin-bottom: 5px;
  color: var(--secondary-color);
  font-family: var(--montserrat);
  font-size: var(--primary-heading);
  font-weight: bolder;
}
.profile-bio {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.profile-logo button {
  padding-top: 10px;
}
.profile-edit button {
  padding-top: unset;
}
.profile-url {
  background: var(--primary-color);
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 5px;
  border-radius: 15px;
}
.profile-url svg {
  font-size: 20px;
  margin: 0 5px;
}
.profile-banner {
  width: fit-content;
  color: white;
  padding: 5px 25px;
  display: flex;
  font-family: var(--montserrat);
  font-weight: bold;
  background-color: var(--secondary-color);
  font-size: var(--secondary-text);
}
.profile-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 15px;
}
.profile-text p {
  margin: unset;
  margin-top: 5px;
  color: #51595e;
}
.profile-text {
  padding-left: 3%;
}
.profile-card-wrapper > div {
  margin-bottom: 7.5px;
}
.profile-url a {
  color: #446fa2;
  text-decoration: none;
}
.profile-text p,
.profile-url {
  font-family: var(--montserrat);
}
.profile-text p,
.profile-text h3 {
  padding-left: 5px;
  word-break: break-all;
}
.appoint-lower {
  height: 325px;
  overflow-y: scroll;
}
.profile-with-card {
  width: 100%;
}
.profile-text-wrapper {
  position: relative;
}
.profile-tabs-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.user-management-cta {
  display: flex;
  column-gap: 5px;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  padding: 5px 25px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  font-family: var(--montserrat);
}
.user-management-cta img {
  width: 18px;
}
.profile-add {
  position: absolute;
  top: -8px;
  right: 0;
  font-family: var(--montserrat);
}
.profile-add button {
  font-size: 9px;
  font-weight: bold;
}
.dashboard-right p.error {
  margin: unset;
  font-size: 12px;
  color: #d32f2f;
  margin-top: 2.5px;
}
.user-profile img {
  border-radius: 50%;
  object-fit: contain;
}
.profile-details:last-child .profile-with-card .profile-card-wrapper {
  max-height: 215px;
  overflow-y: scroll;
}

@media screen and (max-width: 768px) {
  .profile-text-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .profile-add {
    position: unset;
    transform: unset;
    margin-top: -8px;
  }
}

@media screen and (max-width: 600px) {
  .profile-details {
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 10px;
  }
  .profile-bio {
    justify-content: center;
    text-align: center;
  }
  /* .profile-logo img {
    width: 100%;
  } */
}
@media screen and (max-width: 335px) {
}
